import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import SideController from '../components/SideController';
import EditKonvaCanvas from '../components/EditKonvaCanvas';
import LoadingIndicator from '../components/LoadingIndicator';
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';
import config from '../config';
import { handleAxios } from '../components/modules/AxiosModule';
import { makeNewImage, stripBase64Prefix, cropImage } from '../components/modules/functions';
import { textGen } from '../components/modules/imageGen';
import { copyImageToClipboard, downloadImage } from '../components/modules/canvasFuntions';
import TextToolbox from '../components/TextToolbox';

const Edit = () => {
  const location = useLocation();
  const { fetchCredits, checkCredits } = useCredit();
  const { currentUser, loginCheck, authloading } = useAuth();

  const [activeTab, setActiveTab] = useState('layer');

  const [images, setImages] = useState([]);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const transformerRef = useRef(null);
  const stageRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [removingBg, setRemovingBg] = useState(false)

  const [brushSize, setBrushSize] = useState(15);
  const [lines, setLines] = useState([]);
  const [history, setHistory] = useState([{ lines: [] }]);
  const [historyStep, setHistoryStep] = useState(0);

  // 텍스트 관련 속성들을 개별 상태로 관리
  const [textValue, setTextValue] = useState('');
  const [fontSize, setFontSize] = useState(20);
  const [fontFamily, setFontFamily] = useState('Serif');
  const [textColor, setTextColor] = useState('#000000'); // 컬러 입력은 보통 hex 코드로 받습니다.
  const [align, setAlign] = useState('left');           // 수평 정렬: left, center, right
  const [verticalAlign, setVerticalAlign] = useState('top'); // 수직 정렬: top, middle, bottom

  const [textBold, setTextBold] = useState(false);
  const [textItalic, setTextItalic] = useState(false);
  const [textStroke, setTextStroke] = useState(0);
  const [textStrokeColor, setTextStrokeColor] = useState('#ffffff');

  const [canvasWidth, setCanvasWidth] = useState(660);
  const [canvasHeight, setCanvasHeight] = useState(660);
  
  const [isMobile, setIsMobile] = useState(false);

  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const initialHeight = window.innerHeight;
  
  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const inputRef = useRef(null);

  //키보드 올라온 경우 관리용
  const [height, setHeight] = useState(0);
  const divRef = useRef(null);

  useEffect(() => {
    sessionStorage.removeItem('imgUuid');
  }, []);
  
  // 가상 키보드 감지 함수
  // 키보드 감지 시 스크롤 최하단으로 이동
  useEffect(() => {
    const handleViewportChange = () => {
      if (window.visualViewport.height < initialHeight * 0.75) {
        setIsKeyboardVisible(true);
        setTimeout(() => {
          window.scrollTo({
            top: initialHeight,
            behavior: 'smooth'
          });
        }, 100);
      } else {
        setIsKeyboardVisible(false);
      }
    };
    window.visualViewport.addEventListener('resize', handleViewportChange);
    return () => {
      window.visualViewport.removeEventListener('resize', handleViewportChange);
    };
  // eslint-disable-next-line
  }, []);

  //사용자의 환경에 따른 캔버스 사이즈 조정
  const updateCanvasSize = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileRegex = /android|iPad|iPhone|iPod/i;

    if (mobileRegex.test(userAgent.toLowerCase()) || window.innerWidth <= 768) {
      setIsMobile(true);
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth' // 부드럽게 스크롤
        });
      }, 100);
      const newWidth = window.innerWidth * 0.7; // 70% of viewport width
      setCanvasWidth(newWidth);
      setCanvasHeight(newWidth); // Make canvas square
    } else {
      setIsMobile(false);
      setCanvasWidth(660);
      setCanvasHeight(660);
    }
  }, []);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [updateCanvasSize]);

  //페이지 진입 시 로그인 여부 체크
  useEffect(() => {
    loginCheck();
  // eslint-disable-next-line
  }, [currentUser, authloading ]);

  //페이지 진입 시 들고 온 이미지 로드
  useEffect(() => {
    const initializeImage = () => {      
      const state = location.state;
      if (state) {
        let imgSrc;
        if (state.image.b64image) {
          imgSrc = `data:image/png;base64,${state.image.b64image}`; // Base64 기반 이미지
        }
  
        if (imgSrc) {
          const img = new window.Image();
          img.src = imgSrc;
          img.onload = () => {
            const newImage = makeNewImage({
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              url: state.image.url,
              image: img,
              id: 'initial-image'
            })
            setImages([ newImage ]);
            setSelectedId('initial-image');
          };
        }
      }
    };
  
    initializeImage();
  }, [location.state, canvasHeight, canvasWidth]);

  // 레이어 모드
  // 언두
  const undo = () => {
    if (undoStack.length > 0) {
      const newUndoStack = [...undoStack];
      const previousState = newUndoStack.pop();
      setRedoStack([...redoStack, images]);
      setImages(previousState);
      setUndoStack(newUndoStack);
    }
  };
  //리두
  const redo = () => {
    if (redoStack.length > 0) {
      const newRedoStack = [...redoStack];
      const nextState = newRedoStack.pop();
      setUndoStack([...undoStack, images]);
      setImages(nextState);
      setRedoStack(newRedoStack);
    }
  };

  //텍스트 레이어 추가
  const addTextLayer = () => {
    const newTextId = `text-${Date.now()}`;
    const newText = {
      id: newTextId,
      text: textValue,
      fontSize: fontSize,
      fontFamily: fontFamily,
      color: textColor,
      align: align,
      verticalAlign: verticalAlign,
      x: null,       // 필요에 따라 초기 위치를 지정할 수 있습니다.
      y: null,
      width: null,   // 추후에 Konva에서 텍스트 렌더링 후 결정될 수 있음
      height: null,
      rotation: 0,

      textBold: textBold,
      textItalic: textItalic,
      textStroke: textStroke,
      textStrokeColor: textStrokeColor,
    };
    // undoStack에 현재 상태(images)를 저장한 후
    setUndoStack([...undoStack, images]);
    // 새로운 텍스트 레이어를 images 배열에 추가
    setImages(prev => [...prev, newText]);
    // 새로 추가된 텍스트를 선택 상태로 설정
    setSelectedId(newTextId);
    // redo 스택은 초기화
    setRedoStack([]);
    // 로딩 상태 해제 (필요에 따라)
    setIsLoading(false);
  };

  //레이어 제거
  const deleteLayer = () => {
    setUndoStack([...undoStack, images]);
    setRedoStack([]);

    setImages(prev => prev.filter(img => img.id !== selectedId));
    setSelectedId(null);
    transformerRef.current.nodes([]);
    transformerRef.current.getLayer().batchDraw();
  };

  // 레이어 z-index 이동
  const moveLayerForward = () => {
    setUndoStack([...undoStack, images]);
    const index = images.findIndex(img => img.id === selectedId);
    if (index < images.length - 1) {
      const newImages = [...images];
      [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
      setImages(newImages);
      setRedoStack([]);
    }
  };

  const moveLayerBackward = () => {
    setUndoStack([...undoStack, images]);
    const index = images.findIndex(img => img.id === selectedId);
    if (index > 0) {
      const newImages = [...images];
      [newImages[index], newImages[index - 1]] = [newImages[index - 1], newImages[index]];
      setImages(newImages);
      setRedoStack([]);
    }
  };

  // 업로드
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new window.Image();
        img.src = e.target.result;
        img.onload = () => {
          const newImageId = `upimg-${Date.now()}`;
          const newImage = makeNewImage({
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            image: img,
            id: newImageId
          });
          setUndoStack([...undoStack, images]);
          setImages(prev => [...prev, newImage]);
          setSelectedId(newImageId);
          setRedoStack([]);
          setIsLoading(false);
        };
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    }
  };

  // 배경 제거
  const handleBackgroundRemoveClick = async () => {
    if (!selectedId) {
      setModalMsg(ModalMsg().needImgSelect);
      setIsOpen(true);
      return;
    }

    const selectedImage = images.find(img => img.id === selectedId);
    const stage = stageRef.current;
    if (!stage) {
      setModalMsg(ModalMsg().noStage);
      setIsOpen(true);
      return;
    }

    setUndoStack([...undoStack, images]);
    setRedoStack([]);
    setRemovingBg(true);

    try {
      const imageNode = stage.findOne(`#${selectedId}`);
      const imageDataUrl = imageNode.toDataURL({ pixelRatio: 3 });
      const apiRefURL = config.REMOVE_BG_URL;
      const strippedImageData = stripBase64Prefix(imageDataUrl);
      const requestData = {
        image: strippedImageData
      };

      const response = await handleAxios("post", apiRefURL, requestData);

      const img = new window.Image();
      img.src = `data:image/png;base64,${response.data}`;
      img.onload = () => {
        cropImage(img, (croppedBlob) => {
          const objectUrl = URL.createObjectURL(croppedBlob);
          const croppedImage = new window.Image();
          croppedImage.src = objectUrl;
          croppedImage.onload = () => {
            const newImageId = `${selectedImage.id}-${new Date().getTime()}`;
            const newImage = makeNewImage({
              selectedImage: selectedImage,
              croppedImage: croppedImage,
              url: selectedImage.url,
              image: img,
              id: newImageId
            });
            const filteredImages = images.filter(img => img.id !== selectedId);
            setImages([...filteredImages, newImage]);
            setSelectedId(newImage.id);
          };
        });
      };
    } catch (error) {
      setModalMsg(ModalMsg().bgRemoveError);
      setIsOpen(true);
    } finally {
      setRemovingBg(false);
    }
  };

  // 텍스트로 생성
  const handleTextToGenerate = async (prompt) => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return;

    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;

    setIsLoading(true)
    try {
      //텍스트 생성
      const response = await textGen(prompt, currentUser.sub);
  
      if (response.data) {
        fetchCredits()
        const imgSrc = `data:image/png;base64,${response.data.b64image}`;
  
        const img = new window.Image();
        img.src = imgSrc;
        img.onload = () => {
          const newImageId = `genimg-${Date.now()}`;
          const newImage = makeNewImage({
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            url: response.data.url,
            image: img,
            id: newImageId
          });
  
          // 이미지를 레이어에 추가
          setUndoStack([...undoStack, images]);
          setImages(prev => [...prev, newImage]);
          setSelectedId(newImageId);
          setRedoStack([]);
        };
      } else if (response.data && response.data.message) {
        setModalMsg(response.data.message);
        setIsOpen(true);
        // alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          setModalMsg(ModalMsg().noCredits);
          setIsOpen(true);
        } else {
          setModalMsg(ModalMsg().invalidRequest);
          setIsOpen(true);
        }
      } else {
        setModalMsg(ModalMsg().generateFailed);
        setIsOpen(true);
      }
    } finally {
      setIsLoading(false)
    }
  };
  
  // 브러싱 모드
  // 히스토리  
  const lineUndo = useCallback(() => {
      if (historyStep > 0) {
      setHistoryStep(historyStep - 1);
      const previousState = history[historyStep - 1];
      setLines(previousState.lines);
    }
  }, [history, historyStep, setLines]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lineRedo = useCallback(() => {
    if (historyStep < history.length - 1) {
      setHistoryStep(historyStep + 1);
      const nextState = history[historyStep + 1];
      setLines(nextState.lines);
    }});

  // 복사
  const handleCopy = async () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      setModalMsg(ModalMsg().safariCopyFailed);
      setIsOpen(true);
    } else {
      try {
        await copyImageToClipboard(stageRef);
        setModalMsg(ModalMsg().copySuccess);
        setIsOpen(true);
      } catch (error) {
        setModalMsg(ModalMsg().copyFailed);
        setIsOpen(true);
      }
    }
  };
  // 다운로드
  const handleDownload = () => {
    try {
      downloadImage(stageRef);
    } catch (error) {
      setModalMsg(ModalMsg().downloadError);
      setIsOpen(true);
    }
  };

  // 브러싱 영역 b64로 변환
  const maskToBase64 = () => {
    const stage = stageRef.current.getStage();
    
    // 1. 모든 레이어 숨기기
    stage.getLayers().forEach(function(layer) {
      layer.hide();
    });
  
    // 2. 특정 레이어만 표시
    const bgLayer = stage.findOne('.bg');
    bgLayer.show();
    const brushLayer = stage.findOne('.brush');
    brushLayer.show();
    brushLayer.opacity(1.0);
    // 하위 라인 색상 #ffffff로 변경
    brushLayer.getChildren().forEach(function(i) {
      i.stroke('#ffffff');
    });
    // dataUrl로 변환
    const imageDataUrl = stage.toDataURL({
      mimeType: 'image/png',
      quality: 1,
      pixelRatio: 512.0/stage.width(),
    });
    bgLayer.hide();
  
    // 3. 상태를 업데이트
    // setMasked(imageDataUrl);
  
    // 4. 모든 레이어 다시 표시
    stage.getLayers().forEach(function(layer) {
      layer.show();
    });
    brushLayer.opacity(0.5);
    // 하위 라인 색상 #a090ff 변경
    brushLayer.getChildren().forEach(function(i) {
      i.stroke('#a090ff');
    });

    return imageDataUrl;
  };

  // 영역 편집
  const handleEditMask = async (prompt) => {
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return
    // const creditsAvailable = checkCredits(1);
    // if (!creditsAvailable) return;
    setIsLoading(true)
    try {
      const storedToken = JSON.parse(localStorage.getItem('token'));
      const maskedImageDataUrl = stripBase64Prefix(maskToBase64());

      const generates_images = images.filter(item => (item.url && item.url !== '')).map(item => item.url);

      const stage = stageRef.current.getStage();
      ///레이어 전부 hide
      stage.getLayers().forEach(function(layer) {
        layer.hide();
      });

      const imageLayer = stage.findOne('.images')
      imageLayer.show();
      const imageChild = imageLayer.getChildren();
      ///이미지 전부 hide
      imageChild.forEach(item => {
        item.hide();
      });
      const upload_images = [];
      const text_list = [];
      
      ///id가 upimg로 시작하는 원소만 show 후 dataurl로 변환/저장
      imageChild.forEach(item => {
        if (item.attrs.id && item.attrs.id.split('-')[0] === 'upimg') {
          item.show();
          const imageDataUrl = stage.toDataURL({
            mimeType: 'image/png',
            quality: 1,
            pixelRatio: 512.0/stage.width(),
          });
          upload_images.push(stripBase64Prefix(imageDataUrl));
          item.hide();
        }
        else if (item.attrs.id && item.attrs.id.split('-')[0] === 'text') {
          text_list.push(item.attrs);
        }
      });
      // 텍스트 제외 이미지 전부 show
      imageChild.forEach(item => {
        item.show();
        if (item.attrs.id && item.attrs.id.split('-')[0] === 'text') {
          item.hide();
        }
      });

      // images.filter(item => item.url === '').map(item => item.image);
      const image = stage.toDataURL({
        mimeType: 'image/png',
        quality: 1,
        pixelRatio: 512.0/stage.width(),
      });
      
      // 텍스트 전부 show
      imageChild.forEach(item => {
        if (item.attrs.id && item.attrs.id.split('-')[0] === 'text') {
          item.show();
        }
      });

      const brushLayer = stage.findOne('.brush');
      brushLayer.show();

      if (storedToken && storedToken.token) {
        
        // 영역 편집 요청
        const apiURL = config.MASKED_GEN_APIURL;
        const requestData = {
          upload_images: upload_images,
          generates_images: generates_images,
          text_list: text_list,
          prompt: prompt,
          image: stripBase64Prefix(image),
          mask: maskedImageDataUrl,
        };
        const response = await handleAxios("post", apiURL, requestData);
    
        if (response.data) {
          fetchCredits()
          const imgSrc = `data:image/png;base64,${response.data.b64image.replaceAll('"','')}`;
    
          const img = new window.Image();
          img.src = imgSrc;
          img.onload = () => {
            const newImageId = `image-${Date.now()}`;
            const newImage = makeNewImage({
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              url: response.data.url,
              image: img,
              id: newImageId
            });
    
            // 이미지를 레이어에 추가
            setUndoStack([...undoStack, images]);
            setRedoStack([]);
            const newImages = [...[newImage], ...images.filter(i => (i.id && i.id.split('-')[0]==='text'))];
            setImages(newImages);
            setLines([]);
            setHistory([{ lines: [] }]);
            setHistoryStep(0);
          };
        } else if (response.data && response.data.message) {
          setModalMsg(response.data.message);
          setIsOpen(true);
          // alert(response.data.message);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          setModalMsg(ModalMsg().noCredits);
          setIsOpen(true);
        } else {
          setModalMsg(ModalMsg().invalidRequest);
          setIsOpen(true);
        }
      } else {
        setModalMsg(ModalMsg().generateFailed);
        setIsOpen(true);
      }
    } finally {
      setIsLoading(false)
    }
  };

  //페이지 스크롤 방지
  useEffect(() => {
    // 컴포넌트 마운트 시 스타일 적용
    document.body.style.overscrollBehavior = 'none';
    document.body.style.overflow = 'hidden';

    // 컴포넌트 언마운트 시 스타일 원복
    return () => {
      document.body.style.overscrollBehavior = '';
      document.body.style.overflow = '';
    };
  }, []);

  // 보이는 뷰포트 높이를 계산해서 CSS 변수 '--vh'에 설정하는 함수
  const updateVh = () => {
    const viewportHeight = window.visualViewport
      ? window.visualViewport.height
      : window.innerHeight;
    const vh = viewportHeight * 0.01;
    // alert(vh);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    // 최초 계산
    updateVh();

    // 창 크기 변경 및 visualViewport 변화 감지를 위해 이벤트 등록
    window.addEventListener('resize', updateVh);
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', updateVh);
    }

    return () => {
      window.removeEventListener('resize', updateVh);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', updateVh);
      }
    };
  }, []);

  useEffect(() => {
    // 컴포넌트가 마운트된 후, divRef.current가 존재하면 높이를 가져옵니다.
    if (window) {
      setHeight(window.visualViewport.height);
    }
  }, []); // 빈 배열: 최초 마운트 시에만 실행

  // 스크롤 방지
  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };
    
    document.addEventListener('touchmove', preventScroll, { passive: false });
    
    if(divRef.current) {
      divRef.current.addEventListener('touchmove', preventScroll, { passive: true });
    }
    return () => {
      document.removeEventListener('touchmove', preventScroll);
    };
  }, []);

  return (
    <div className='flex flex-col h-[100vh] pt-[48px] gap-4 overflow-hidden
              sm:flex-row sm:h-screen sm:mx-5 sm:mb-6 sm:pt-0'>
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>

      {isLoading && <LoadingIndicator text="로보브러시가 열심히 일하고 있습니다." />}
      {removingBg && <LoadingIndicator text="로보브러시가 이미지의 배경을 제거하고 있습니다." />}

      <div className={`flex flex-col h-[calc(100%-48px)] mb-auto
                      order-1 justify-center items-center gap-4
                      sm:order-2 sm:pt-0 sm:mx-auto 
                      ${isKeyboardVisible ? 'pt-[12px]' : 'pt-[72px]'}`}
        ref={divRef}
        style={{
          height: '100%',
          // height: 'calc(var(--vh, 1vh) * 100)',
          marginTop: `calc(${height}px - (var(--vh, 1vh) * 100))`
          // marginTop: height - window.visualViewport.height,
        }}>
        {selectedId && selectedId.split('-')[0]==='text' ? ( 
        <TextToolbox
          isKeyboardVisible={isKeyboardVisible}
          isMobile={isMobile}
          images={images}
          setImages={setImages}
          undoStack={undoStack}
          redoStack={redoStack}
          setUndoStack={setUndoStack}
          setRedoStack={setRedoStack}
          selectedId={selectedId}
          setTextValue={setTextValue}
          fontSize={fontSize}
          setFontSize={setFontSize}
          fontFamily={fontFamily}
          setFontFamily={setFontFamily}
          color={textColor}
          setColor={setTextColor}
          align={align}
          setAlign={setAlign}
          verticalAlign={verticalAlign}
          setVerticalAlign={setVerticalAlign}
          textBold={textBold}
          setTextBold={setTextBold}
          textItalic={textItalic}
          setTextItalic={setTextItalic}
          textStroke={textStroke}
          setTextStroke={setTextStroke}
          textStrokeColor={textStrokeColor}
          setTextStrokeColor={setTextStrokeColor}
        />
        ) : (<div className='w-[75%] sm:w-[auto] p-1 sm:p-2'><div className="h-5 sm:h-9" /></div>)}
        <EditKonvaCanvas
          setImages={setImages}
          images={images}
          setUndoStack={setUndoStack}
          undoStack={undoStack}
          setRedoStack={setRedoStack}
          redoStack={redoStack}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          transformerRef={transformerRef}
          stageRef={stageRef}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          activeTab={activeTab}
          brushSize={brushSize}
          lines={lines}
          setLines={setLines}
          history={history}
          setHistory={setHistory}
          historyStep={historyStep}
          setHistoryStep={setHistoryStep}
          undo={undo}
          redo={redo}
          lineUndo={lineUndo}
          lineRedo={lineRedo}
          
          setTextValue={setTextValue}
          setFontSize={setFontSize}
          setFontFamily={setFontFamily}
          setColor={setTextColor}
          setAlign={setAlign}
          setVerticalAlign={setVerticalAlign}

          textBold={textBold}
          setTextBold={setTextBold}
          textItalic={textItalic}
          setTextItalic={setTextItalic}
          textStroke={textStroke}
          setTextStroke={setTextStroke}
          textStrokeColor={textStrokeColor}
          setTextStrokeColor={setTextStrokeColor}
          inputRef={inputRef}
        />
      </div>

      <div className='sm:order-1 order-2 mt-auto mb-0'>
        <SideController
          setImages={setImages}
          images={images}
          isKeyboardVisible={isKeyboardVisible}
          handleEditMask={handleEditMask}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          undo={undo}
          redo={redo}
          undoStack={undoStack}
          redoStack={redoStack}
          setUndoStack={setUndoStack}
          setRedoStack={setRedoStack}
          addTextLayer={addTextLayer}
          deleteLayer={deleteLayer}
          moveLayerForward={moveLayerForward}
          moveLayerBackward={moveLayerBackward}
          handleImageUpload={handleImageUpload}
          handleTextToGenerate={handleTextToGenerate}
          handleBackgroundRemoveClick={handleBackgroundRemoveClick}
          selectedId={selectedId}
          fileInputRef={fileInputRef}
          removingBg={removingBg}
          brushSize={brushSize}
          setBrushSize={setBrushSize}
          lines={lines}
          lineUndo={lineUndo}
          lineRedo={lineRedo}
          history={history}
          historyStep={historyStep}
          handleDownload={handleDownload}
          handleCopy={handleCopy}
          canvasHeight={canvasHeight}
          isMobile={isMobile}

          textValue={textValue}
          setTextValue={setTextValue}
          fontSize={fontSize}
          setFontSize={setFontSize}
          fontFamily={fontFamily}
          setFontFamily={setFontFamily}
          color={textColor}
          setColor={setTextColor}
          align={align}
          setAlign={setAlign}
          verticalAlign={verticalAlign}
          setVerticalAlign={setVerticalAlign}
          inputRef={inputRef}
        />
      </div>
      
    </div>
  );
};

export default Edit;
