import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import firestore from '../firebaseConfig';
import AdminNavBar from '../components/adminNavBar';
import LoadingIndicator from '../components/LoadingIndicator';

const AdminFabLike = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fabLikes, setFabLikes] = useState([]);
  const [lastDoc, setLastDoc] = useState(null); // 페이지네이션을 위한 마지막 문서
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [totalPages, setTotalPages] = useState(1); // 전체 페이지
  const itemsPerPage = 20; // 한 페이지당 수

  // Firestore 호출
  const fetchFabLikes = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const collectionRef = collection(firestore, 'fabLike');
      let q = query(collectionRef, orderBy("createdAt", "desc"), limit(itemsPerPage));

      // 페이지네이션: 첫 페이지가 아닐 경우 lastDoc 이후부터 가져오기
      if (pageNumber > 1 && lastDoc) {
        q = query(collectionRef, orderBy("createdAt", "desc"), startAfter(lastDoc), limit(itemsPerPage));
      }

      const querySnapshot = await getDocs(q);
      const newFabLikes = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setFabLikes(newFabLikes);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // 마지막 문서 저장
      setCurrentPage(pageNumber); // 현재 페이지 업데이트

      if (pageNumber === 1) {
        // 첫 페이지일 때 전체 페이지 수 계산
        const totalDocs = await getDocs(collectionRef);
        setTotalPages(Math.ceil(totalDocs.size / itemsPerPage));
      }
    } catch (error) {
      console.error('Error fetching fabLike data:', error);
    }
    setIsLoading(false);
  };

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber) => {
    setIsLoading(true);
    fetchFabLikes(pageNumber);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    fetchFabLikes(1);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <AdminNavBar />
      {isLoading && <LoadingIndicator />}

      {/* 리스트 영역 */}
      <div className="md:mx-80 mt-32">
        <div className="p-4 space-y-6">
          {fabLikes.map((fab) => (
            <div key={fab.id} className="p-4 bg-gray-800 rounded-lg shadow-md border border-gray-700">
              <p className="text-lg font-semibold">
                {fab.name || '비회원'} 
                <span className="text-sm text-gray-400"> {fab.sub}</span>
                <br />
                <span className="font-bold text-white"> {fab.tool} </span>
                <span className={`text-xl font-bold ${fab.like === 'like' ? 'text-green-500' : 'text-red-500'}`}>
                  {fab.like}
                </span>
              </p>

              {fab.url && (
                <div className="mt-3">
                  <div className="flex gap-2">
                    {Array.isArray(fab.url) ? (
                      fab.url.map((img, index) => (
                        <img key={index} src={img} alt={`fabLike-${index}`} className="md:w-60 w-24 object-cover rounded-md" />
                      ))
                    ) : (
                      <img src={fab.url} alt="fabLike" className="md:w-60 w-24 object-cover rounded-md" />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 페이지네이션 (숫자 버튼) */}
        {totalPages > 1 && (
          <div className="flex justify-center space-x-2 my-6">
            {[...Array(totalPages).keys()].map((_, index) => {
              const pageNumber = index + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={`px-4 py-2 rounded-lg ${
                    currentPage === pageNumber ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                  } hover:bg-blue-700 transition duration-300`}
                >
                  {pageNumber}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminFabLike;