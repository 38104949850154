import React, { useState } from "react";
import ConfirmModal from "./modals/ConfirmModal";

const sampleImages = [
  require("../assets/images/studio/studioSample00.png"),
  require("../assets/images/studio/studioSample01.png"),
  require("../assets/images/studio/studioSample02.png"),
  require("../assets/images/studio/studioSample03.png"),
];

const StudioSampleImage = ({ handleImageUpload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // 샘플 이미지 클릭 -> 모달 열기 & 이미지 저장
  const handleSampleClick = (imageSrc) => {
    setSelectedImage(imageSrc); // 선택된 이미지 저장
    setIsModalOpen(true); // 모달 열기
  };

  // 닫기 동작
  const handleClose = () => {
    setIsModalOpen(false);
    setIsModalOpen(null);
  }

  // 모달에서 "예" 클릭 시 이미지 업로드 실행
  const handleConfirmUpload = async () => {
    if (!selectedImage) return;
    setIsModalOpen(false); // 모달 닫기

    try {
      const response = await fetch(selectedImage);
      const blob = await response.blob();
      const file = new File([blob], "sample-image.png", { type: "image/png" });

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.files = dataTransfer.files;

      // 기존 handleImageUpload 함수 호출
      handleImageUpload({ target: fileInput });
    } catch (error) {
      console.error("샘플 이미지 업로드 오류:", error);
    }
  };

  return (
    <div className="relative mt-0 md:mt-2">
      <p className="text-xl md:text-3xl font-bold text-center">
        제품 이미지가 없다면 샘플 이미지로
        <br />
        AI스튜디오를 체험해보세요!
      </p>

      {/* 스크롤 가능한 그리드 박스 */}
      <div className="mt-2 md:mt-4 max-h-[360px] md:max-h-[460px] w-[85%] md:w-[45%] mx-auto overflow-y-auto p-2 rounded-lg border border-gray-200">
        <div className="grid grid-cols-2 gap-2">
          {sampleImages.map((src, index) => (
            <div
              key={index}
              className="cursor-pointer overflow-hidden rounded-lg shadow-lg hover:scale-[1.02] transition-transform"
            >
              <img
                src={src}
                alt={`샘플 이미지 ${index + 1}`}
                id="studio-samples"
                className="w-full h-auto object-cover"
                onClick={() => handleSampleClick(src)}
              />
            </div>
          ))}
        </div>
      </div>

      <ConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmUpload}
        yesButtonId="confirm-sample-upload"
        noButtonId="cancel-sample-upload"
      >
        <img src={selectedImage} alt="선택한 이미지"/>
        <p className="mt-8 text-md md:text-xl text-black font-semibold mb-4">
          해당 이미지로 광고 이미지를 생성하시겠습니까?
        </p>
      </ConfirmModal>
    </div>
  );
};

export default StudioSampleImage;