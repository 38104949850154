import React from "react";
// import { FaInstagram } from 'react-icons/fa';
// import { AiFillDiscord } from 'react-icons/ai';

function Texts( { children }) {
  return (
    <div className="block text-[13px] leading-[1.6] text-[#a7a7a7] font-semibold break-keep font-[300] 
                    md:text-[14px] md:inline">
      {children}
    </div>
  )
}

function Span( { children , value=''} ) {
  return (
    <span className={`leading-[1.6] text-white font-semibold break-keep
                    ${value === 'title'
                    ? 'text-[15px] md:text-[16px]'
                    : 'text-[13px] md:text-[14px]'}
                    `}>
      {children}
    </span>
  )
}

function FooterContainer( { children } ) {
  return (
    <div className="w-auto mx-auto p-4 grid grid-cols-3 gap-y-2 justify-between 
                    md:flex md:w-[1440px] md:h-[200px] md:py-10">
      {children}
    </div>
  )
}

// function LinkIcon( { children } ) {
//   return (
//     <div className="flex text-white ml-auto md:ml-0">
//       {children}
//     </div>
//   )
// }

// function Br() {
//   return <br className='hidden md:block' />;
// }

function Footer() {
  const currentYear = new Date().getFullYear();

    return (
      <div className="w-full bg-black">
        <FooterContainer>
          <div className="block col-span-3 md:flex md:flex-col md:gap-2">
            <Span value='title'>© {currentYear} IMAGINERY.Inc</Span>
            <Texts>
              <Span>회사명</Span> 주식회사 이매지너리
            </Texts>
            <Texts>
              <Span> 주소</Span> 서울특별시 마포구 월드컵북로 98, 2층
              <Span> 대표</Span> 신준호
              <Span> 사업자등록번호</Span> 782-81-02642
            </Texts>
            <Texts>
              <Span> 통신판매업</Span> 제2023-서울마포-1651 호
              <Span> 대표번호</Span> 0507-1355-9741
              <Span> 이메일</Span> business@robobrush.ai
            </Texts>
          </div>
          <div className="block md:flex md:flex-col md:gap-2">
            <Texts>
              <a href="/mycanvas-home">AI 캔버스</a>
            </Texts>
            <Texts>
              <a href="/conceptlanding">AI 스튜디오</a>
            </Texts>
          </div>
          <div className="block md:flex md:flex-col md:gap-2">
            <Texts>
              <a href="/pricing">요금제</a>
            </Texts>
            <Texts>
              <a href="/terms/service">서비스 이용약관</a>              
            </Texts>
            <Texts>
              <a href="/terms/privacy">개인정보 처리방침</a>
            </Texts>
          </div>
          <div className="block md:flex md:flex-col md:gap-2">
            <Texts>
            <a href="https://www.instagram.com/imaginery_ai/" id="foot-insta" target="_blank" rel="noopener noreferrer">인스타그램</a>
            </Texts>
            <Texts>
              <a href="https://discord.gg/HmWfFD8Vmg" id="foot-discord" target="_blank" rel="noopener noreferrer">디스코드</a>
            </Texts>
          </div>
          {/* <LinkIcon className="block md:inline">
            <a href="https://discord.gg/HmWfFD8Vmg" id="foot-discord" target="_blank" rel="noopener noreferrer"
                className="ml-[8px] text-[26px]
                          md:ml-[12px] md:text-[50px]">
              <AiFillDiscord />
            </a>
            <a href="https://www.instagram.com/imaginery_ai/" id="foot-insta" target="_blank" rel="noopener noreferrer"
                className="ml-[8px] text-[26px]
                          md:ml-[12px] md:text-[50px]">
              <FaInstagram />
            </a>
          </LinkIcon> */}
        </FooterContainer>
      </div>
    )
  }

export default Footer;