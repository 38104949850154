import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import TermAds from '../../assets/terms/term-ads';
import TermUse from '../../assets/terms/term-use.png';
import config from '../../config';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { signupComplete } from '../modules/signupFunctions';
import { X } from 'lucide-react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className='modal md:w-[768px]' onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          <X className='w-4 md:w-6 h-4 md:h-6'/>
        </button>
        <div className="modal-content max-h-[500px] md:max-h-[500px] overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const TermModal = ({isTermModal, onClose, children}) => {
  return (
    <Modal isOpen={isTermModal} onClose={onClose}>
      { children}
    </Modal>
  );
};

const AgreementModal = ({ setIsAgree, requestData }) => {
  const [isTermModal, setIsTermModal] = useState(false);
  const [term, setTerm] = useState('');
  const [phone, setPhone] = useState('');
  const [over14, setOver14] = useState(false);
  const [marketingAgree, setMarketingAgree] = useState(false);  // 창작 콘텐츠 홍보/광고 활용 동의
  const [adsAgree, setAdsAgree] = useState(false);  // 광고성 정보 수신 동의

  const { setMyToken, setGuestWater } = useAuth();

  const endpoint = config.TERMS_AGREEMENT;

  const submitAgree = async () => {
    try {
      const data = {
        ...requestData,
        phone: phone,
        over14: over14,
        content_use: marketingAgree,
        ad_consent: adsAgree,
      };
      // 동의 처리
      const axiosResponse = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        }
      });
      setMyToken( data, axiosResponse )
      setGuestWater(axiosResponse.data.showWatered)
      signupComplete(axiosResponse);

      setIsAgree(true); // 동의 버튼 클릭시 동의 상태 변경
    } catch (error) {
      alert('동의 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
      console.error('Error fetching user info or processing login:', error);
    }
  }

  const handleSubmit = async () => {

    submitAgree();

  }

  const openTermModal = ( t ) => {
    setTerm(t);
    setIsTermModal(true);
  }

  const checkRegex = (value) => {
    const sqlInjectionRegex = /['"\\;-]/;
    // 정규표현식으로 SQL 인젝션 패턴 감지
    if (sqlInjectionRegex.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  return ReactDOM.createPortal(
    <div className='modal-overlay'>
      <TermModal isTermModal={isTermModal} onClose={() => setIsTermModal(false)}>
        { term }
      </TermModal>
      <div className='modal w-[324px] md:w-[512px]'>
        <div className='modal-title break-keep'>서비스 이용을 위해 아래 약관을 확인해주세요.</div>
        <form 
          className='modal-content'
          onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          
          <div className='flex'>
            <input 
              className='mr-2 md:mr-4' 
              type='checkbox'
              onChange={(e) => {setOver14(e.target.checked)}}
              required
            />
            <label htmlFor='age-agree'>[필수] 만 14세 이상입니다.</label>
          </div>
          <br />
          <div className='flex'>
            <input 
              className='mr-2 md:mr-4' 
              type='checkbox'
              onChange={(e) => {setMarketingAgree(e.target.checked)}}
            />
            <label htmlFor='marketing-agree'>[선택] 창작 콘텐츠 홍보/광고 활용 동의</label>
            <button type='button' className='ml-auto mr-0 underline' onClick={() => openTermModal( <img src={TermUse} alt="이용약관" /> )}>자세히</button>
          </div>
          <br />
          <div className='flex'>
            <input 
              className='mr-2 md:mr-4' 
              type='checkbox'
              onChange={(e) => {setAdsAgree(e.target.checked)}}
            />
            <label htmlFor='ads-agree'>[선택] 광고성 정보 수신 동의</label>
            <button type='button' className='ml-auto mr-0 underline' onClick={() => openTermModal( TermAds )}>자세히</button>
          </div>
          <hr className='my-8'/>
          <div className='flex w-full'>
            <label className='w-1/4 mr-4' htmlFor="phone">
              전화번호
              <span className='text-red-500'>*</span>
            </label>
            <div className='w-3/4'>
              <input
                placeholder="010-1234-5678"
                className="w-full tracking-widest"
                value={phone}
                onChange={(e) => checkRegex(e.target.value) ?  setPhone(e.target.value) : null}
                required
              />
            </div>
          </div>
          <br /><br />
          <div className='btn-border-gradient w-[200px] h-[42px] md:h-[52px] mx-auto mt-auto mb-0 p-[2px]'>
            <button 
              className='btn-solid-white' 
              type='submit'
            >
              가입하기
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
};

export { Modal, TermModal, AgreementModal };