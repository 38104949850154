import { useFab } from "../../context/FabContext";
import { FabIcon } from "./FabIcon";

const FabButton = () => {
  const { isButtonVisible, setIsButtonVisible, setIsMenuVisible } = useFab();

  const handleClick = () => {
    setIsButtonVisible(false)
    setIsMenuVisible(true)
  };

  if (!isButtonVisible) return null;

  return (
    <button
      id="fab-button"
      onClick={handleClick}
      className={`
        w-10 h-10 md:w-14 md:h-14 text-white rounded-full shadow-lg flex items-center justify-center transition
        md:mr-0 md:mb-0 mr-2 mb-2
        hover:scale-[1.05]
        `}
    >
      <FabIcon />
    </button>
  );
};

export default FabButton;