import React, { useEffect } from 'react';
import { GradientButton } from '../Buttons';
import { IoClose } from 'react-icons/io5';

const PhoneModal = ({ isOpen, setIsOpen, message, message1, message2, onConfirm, setModalValue, modalValue }) => {

  const isValidPhoneNumber = (value) => {
    return /^\d{3}-\d{4}-\d{4}$/.test(value) || /^\d{11}$/.test(value);
  };

  const Confirm = () => {
    if (isValidPhoneNumber(modalValue)) {
      onConfirm();
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    // 숫자랑 -만 입력 가능
    const sanitizedInput = input.replace(/[^\d-]/g, '');
    setModalValue(sanitizedInput);
  };

  useEffect(() => {
    const handlePopState = (e) => {
      if (isOpen) {
        setIsOpen(false); // 뒤로가기로 닫기
        setModalValue('');
      }
    };

    if (isOpen) {
      window.history.pushState({ modalOpen: true }, '', window.location.href); // 가짜 스택 추가
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
      if (isOpen && window.history.state?.modalOpen) {
        window.history.back(); // 클린업에서 스택 제거
      }
    };
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white pt-10 pb-6 px-8 m-4 rounded-lg shadow-lg text-center max-w-xl w-full font-bold relative">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={() => {
            setIsOpen(false)
            setModalValue('')
          }}
        >
          <IoClose size={24} />
        </button>

        <h2 className="text-[5.7vw] sm:text-3xl">{message}</h2>
        {message1 && <h2 className="text-[5.7vw] sm:text-3xl mt-1">{message1}</h2>}
        {message2 && <h2 className="text-[5.8vw] sm:text-4xl mt-6 sm:mt-16">{message2}</h2>}

        <div className="mt-16">
          <input
            type="text"
            value={modalValue}
            onChange={handleInputChange}
            placeholder="010-1234-5678"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-600 sm:text-2xl text-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mt-8 mx-[auto] flex justify-center">
          <GradientButton
            btnId="signup-modal-yes"
            text="휴대폰 번호 저장"
            radius="10px"
            fontsize="24px"
            mobileFontSize="21px"
            size="w-[100%] h-[52px] sm:w-[100%] sm:h-[58px]"
            handleClick={Confirm}
            disabled={!isValidPhoneNumber(modalValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneModal;