import { useState } from "react";
import { X, ArrowLeft } from "lucide-react";
import { FabIcon } from "./FabIcon";
import { GradientOutlineButton } from "../Buttons";
import { gradientBg } from "../modules/colors";
import firestore from "../../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";

const FabSuggestion = ({ onBack, onClose }) => {
  const { currentUser } = useAuth();
  const [suggestion, setSuggestion] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [completeText, setCompleteText] = useState('')

  // Firestore에 저장하는 함수
  const handleSubmit = async () => {
    try {
      await addDoc(collection(firestore, "fabSuggestion"), {
        sub: currentUser?.sub || "",
        name: currentUser?.nickname || currentUser?.name || "",
        email: currentUser?.email || "",
        context: suggestion,
        createdAt: serverTimestamp(),
      });

      setCompleteText('소중한 의견 감사합니다.')
      setIsSubmitted(true);

      setTimeout(() => {
        onClose();
        setIsSubmitted(false);
      }, 3000);
    } catch (error) {
      setCompleteText('전송 중 오류가 발생했습니다.')
      setIsSubmitted(true);

      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
    }
  };

  return (
    <div className="px-4 pb-2 md:p-0 w-[100vw] md:w-auto ">
      <div className={`inset-0 p-[3px] ${gradientBg} rounded-lg transition md:w-auto`}>
        <div className="relative bg-[#24303F] text-white rounded-lg shadow-lg p-6 transition">

          {isSubmitted ? (
            <div>
              <FabIcon />
              <p className="text-center text-[20px] text-white mt-6 pb-4 px-2">
                {completeText}
              </p>
            </div>
          ) : (
            <>
              <div className="absolute top-2 right-2 flex gap-2">
                <button
                  onClick={onBack}
                  className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600 transition"
                >
                  <ArrowLeft className="w-5 h-5 text-white" />
                </button>
                <button
                  onClick={onClose}
                  className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600 transition"
                >
                  <X className="w-5 h-5 text-white" />
                </button>
              </div>

              <FabIcon />

              <p className="pt-4 text-[16px] text-white">
                건의 내용을 아래 입력창에 작성 후
              </p>
              <p className="pb-4 text-[16px] text-white">
                보내기 버튼을 누르면 관리자에게 전송돼요!
              </p>

              <textarea
                className="md:min-w-[440px] w-full h-40 bg-white text-black rounded p-2 mt-2"
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
              />

              <div className="pt-4 flex justify-center">
                <GradientOutlineButton
                  btnId="fab-suggest-submit"
                  text="보내기"
                  handleClick={handleSubmit}
                  disabled={!suggestion.trim()}
                  size='w-[240px] h-[50px] md:w-[270px] md:h-[50px]'
                  mobileFontSize='18px'
                />
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default FabSuggestion;