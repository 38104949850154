import React, { useEffect, useState } from 'react';
import { Images, WandSparkles, Download, Type, Undo2, Redo2 } from 'lucide-react';
import { OutlineButton, GradientOutlineButton, AreaEditButton } from './Buttons';
import { InputBtn, InputTextArea } from './Inputs';
import { FaCoins } from 'react-icons/fa';
import { gradientBg } from './modules/colors';

const SideController = ({ 
  isKeyboardVisible, isMobile, 
  images, setImages,
  handleEditMask, handleImageUpload, handleBackgroundRemoveClick, handleTextToGenerate, handleCopy, handleDownload, 
  undo, redo, undoStack, redoStack, setUndoStack, setRedoStack, 
  deleteLayer, addTextLayer, moveLayerForward, moveLayerBackward, removingBg, 
  selectedId, fileInputRef, 
  setActiveTab, activeTab, 
  brushSize, setBrushSize, lineUndo, lineRedo, lines, 
  history, historyStep,
  textValue, setTextValue, inputRef,
  }) => {
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const maxLength = 100;

  // 탭 목록 색상 관련
  const getTextColor = (tabName) => {
    return activeTab === tabName ? 'text-white' : 'text-[#D0D0D0]';
  };
  const getIconBackground = (tabName) => {
    return activeTab === tabName ? 'bg-[#933BFF]' : 'bg-transparent';
  };

  // 모바일에서 텍스트 에어리어 클릭시, input창으로 포커스
  const handleFocusTextArea = (id) => {
    if (isMobile) {
      setTimeout(() => {
        const input = document.querySelector(id);
        // const input = document.querySelector('#edit-areaEdit-input');
        if (input) {
          input.focus();
        } 
      }, 0);
    }
  };
  
  const handleGenerateClick = async (genFuction, value) => {
    if (value) {
      setIsGenerating(true);
      setProgress(0);

      let animationProgress = 0;
      const intervalId = setInterval(() => {
        animationProgress += 1;
        if (animationProgress <= 90) { 
          setProgress(animationProgress);
        } else {
          clearInterval(intervalId);
        }
      }, 150); // 150ms마다 1%씩 증가

      await genFuction(value);

      // 서버 요청 완료 시
      setProgress(100);

      clearInterval(intervalId);
      setTimeout(() => {
        setIsGenerating(false);
        setProgress(0);
        setIsInputVisible(false); // 완료 후 원래 버튼으로 복귀
        setActiveTab('layer')
      }, 500); // 애니메이션 완료 후 잠깐 유지 후 초기화
    }
  }; 

  // 입력폼의 값 변경 시, 폼 상태와 선택된 오브젝트(있는 경우)를 업데이트합니다.
  const handleInputChange = (key, value) => {
    // 폼 상태 업데이트
    switch (key) {
      case 'text':
        if (value.length < maxLength) {
          setTextValue(value);
        } else {
          setTextValue(value.slice(0, maxLength));
        }
        break;
      default:
        break;
    }
    // 선택된 오브젝트가 있다면 해당 오브젝트의 속성도 업데이트
    if (selectedId && selectedId.split('-')[0] === 'text'){
      if (key === 'text') {//텍스트변경일때
        if (value.length <= maxLength) {
          // undoStack에 현재 상태(images)를 저장한 후
          setUndoStack([...undoStack, images]);
          // redo 스택은 초기화
          setRedoStack([]);
          setImages(prev =>
            prev.map(item =>
              item.id === selectedId ? { ...item, [key]: value.slice(0, maxLength) } : item
            )
          );
        }
      }
      else if (key!=='color') {
        // undoStack에 현재 상태(images)를 저장한 후
        setUndoStack([...undoStack, images]);
        // redo 스택은 초기화
        setRedoStack([]);
      }
      else {//아닐 때
        setImages(prev =>
          prev.map(item =>
            item.id === selectedId ? { ...item, [key]: value } : item
          )
        );
      }
    }
  };

  // redo undo 단축키로 실행
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (activeTab === 'layer') {
        if (e.ctrlKey && e.key === 'z' && !e.shiftKey) {
          e.preventDefault();
          undo();
        }
        if (e.ctrlKey && e.key === 'Z' && e.shiftKey) {
          e.preventDefault();
          redo();
        }
      } else if (activeTab === 'brushing') {
        if (e.ctrlKey && e.key === 'z' && !e.shiftKey) {
          e.preventDefault();
          lineUndo();
        }
        if (e.ctrlKey && e.key === 'Z' && e.shiftKey) {
          e.preventDefault();
          lineRedo();
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [undo, redo, lineUndo, lineRedo, activeTab]);
  

  // 브러시 크기 슬라이더
  const handleSliderMouseUp = (e) => {
    setBrushSize(parseInt(e.target.value)); // 슬라이더에서 손을 뗀 후에만 브러시 크기를 업데이트
  };
  // 모바일에서 터치 대응 해야 정상 작동함
  const handleSliderTouchEnd = (e) => {
    setBrushSize(parseInt(e.target.value));
  };

  // 탭 리스트
  const tabs = [
    {
      name: 'layer',
      tabId: 'edit-layer-tab',
      label: '레이어 편집',
      Icon: Images,
    },
    {
      name: 'brushing',
      tabId: 'edit-area-tab',
      label: '영역 편집',
      Icon: WandSparkles,
    },
    {
      name: 'text',
      tabId: 'edit-text-tab',
      label: '텍스트 편집',
      Icon: Type,
    },
    {
      name: 'export',
      tabId: 'edit-export-tab',
      label: '내보내기',
      Icon: Download,
    },
  ];
  
  return (
    <div className={`w-full sm:w-auto sm:h-[89vh] sm:mb-6 text-white flex flex-row overflow-hidden
                    ${isKeyboardVisible ? 'h-[60px] z-[10000]' : 'h-[260px] z-[100]'}`}>
      {/* 탭 선택 영역 */}
      <div className={`sm:w-[auto] w-[17%] h-auto sm:h-[400px] sm:p-[15px] flex flex-col sm:gap-[5px] items-center bg-[#24303F] shadow-md p-[1px] gap-[1px]
                      sm:rounded-tl-[15px] sm:rounded-bl-[15px] rounded-bl-0 rounded-tl-[15px]`}>
        {!isKeyboardVisible ? (
          // 키보드가 보이지 않을 때 모든 탭 노출
          tabs.map((tab) => (
            <button className="p-2 flex flex-col items-center"
              id={tab.tabId}
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}>
              <div className={`rounded-[25px] sm:py-[10px] sm:px-[15px] py-[5px] px-[10px] ${getIconBackground(tab.name)}`}>
                <tab.Icon className={`${getTextColor(tab.name)} sm:size-[28px] size-[16px]`} />
              </div>
              <span className={`mt-1 sm:text-[12px] text-[9px] ${getTextColor(tab.name)}`}>
                {tab.label}
              </span>
            </button>
          ))
        ) : (
          // 키보드가 보일 때 활성화된 탭만 노출
          <>
            {tabs.map((tab) => {
              if (tab.name === activeTab) {
                return (
                  <button className="p-2 flex flex-col items-center"
                    key={tab.name}
                    onClick={() => setActiveTab(tab.name)}>
                    <div className={`rounded-[25px] sm:py-[10px] sm:px-[15px] py-[5px] px-[10px] ${getIconBackground(tab.name)}`}>
                      <tab.Icon className={`${getTextColor(tab.name)} sm:size-[28px] size-[16px]`} />
                    </div>
                    <span className={`mt-1 sm:text-[12px] text-[9px] ${getTextColor(tab.name)}`}>
                      {tab.label}
                    </span>
                  </button>
                );
              }
              return null;
            })}
          </>
        )}
      </div>

      {/* 각 탭에 따른 메뉴 */}
      <div className={`w-[83%] sm:w-[auto] bg-[#24303F] sm:p-[20px] py-[15px] px-[20px] shadow-md flex flex-col gap-4 sm:rounded-bl-[15px] sm:rounded-br-[15px] rounded-br-0 rounded-tr-[15px]`}>
        {activeTab === 'layer' && (
          <div className={`sm:flex sm:flex-col gap-2 sm:gap-4 ${!isKeyboardVisible ? 'grid grid-cols-2' : 'pr-[10px]'}`}>

            {!isKeyboardVisible && <h3 className={h3style}>레이어 추가/제거</h3>}
            {!isKeyboardVisible && (<OutlineButton text="이미지 업로드" handleClick={() => fileInputRef.current.click()} btnId="edit-upload" />)}
            {!isKeyboardVisible && (<OutlineButton text="레이어 제거" handleClick={deleteLayer} disabled={!selectedId} btnId="edit-deleteLayer" />)}
            {isInputVisible ? (
              <InputBtn
                isKeyboardVisible={isKeyboardVisible}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleGenerateClick={() => handleGenerateClick(handleTextToGenerate, inputValue)}
                isGenerating={isGenerating}
                progress={progress}
                placeholder="귀여운 강아지"
                btnId="edit-textGenerate-wand"
              />
            ) : (
              <GradientOutlineButton text="텍스트로 이미지 생성" leftIcon={<FaCoins className="text-[12px] sm:text-[24px] align-middle" />} handleClick={() => setIsInputVisible(true)} btnId="edit-textGenerate" />
            )}
            {!isKeyboardVisible &&
            <GradientOutlineButton
              btnId="edit-removeBG"
              text="배경 제거"
              handleClick={handleBackgroundRemoveClick}
              disabled={!selectedId || selectedId.split('-')[0]==='text'}
              activating={removingBg}
              actText="배경 제거 중..."
            />
            }

            {!isKeyboardVisible && <h3 className={h3style}>레이어 순서변경</h3>}
            {!isKeyboardVisible && <OutlineButton text="레이어를 앞으로" handleClick={moveLayerForward} disabled={!selectedId} btnId="edit-layer-gofront" />}
            {!isKeyboardVisible && <OutlineButton text="레이어를 뒤로" handleClick={moveLayerBackward} disabled={!selectedId} btnId="edit-layer-goback" />}

            <div className="hidden sm:flex sm:flex-col sm:gap-4">
              {!isKeyboardVisible && <h3 className={h3style}>실행 변경</h3>}
              {!isKeyboardVisible && <OutlineButton className="sm:block hidden" text="실행 취소" handleClick={undo} disabled={undoStack.length === 0}
                leftIcon={<Undo2 className="text-[12px] sm:text-[24px] align-middle" />} btnId="edit-layer-undo" />}
              {!isKeyboardVisible && <OutlineButton className="sm:block hidden" text="다시 실행" handleClick={redo} disabled={redoStack.length === 0}
                leftIcon={<Redo2 className="text-[12px] sm:text-[24px] align-middle"/>} btnId="edit-layer-redo" />}
            </div>
          </div>
        )}
        {activeTab === 'brushing' && (
          <div className={`sm:flex sm:flex-col gap-4 sm:gap-4 ${!isKeyboardVisible ? 'grid grid-cols-1' : 'pr-[10px]'}`}>
            
            <div className={isKeyboardVisible ? "" : "absolute -left-[9999px]"}>
              <InputBtn
                inputValue={prompt}
                setInputValue={setPrompt}
                handleGenerateClick={() => handleGenerateClick(handleEditMask, prompt)}
                placeholder="텍스트를 입력하여 선택한 영역에 수정을 해보세요."
                btnId="edit-areaEdit"
                inputId="edit-areaEdit-input"
              />
            </div>

            {!isKeyboardVisible && (
              <div className="flex flex-col items-center justify-between">
                <label className="sm:text-[18px] text-[14px] mb-4"
                  htmlFor="brushSize">Brush Size: {brushSize}px</label>
                <input
                  id="brushSize"
                  type="range"
                  min="1"
                  max="50"
                  defaultValue={brushSize}
                  onMouseUp={handleSliderMouseUp}
                  onTouchEnd={handleSliderTouchEnd}
                  className="w-full sm:h-[6px] h-[5px]"
                />
              </div>
            )}

            <div className={`flex flex-col sm:gap-4 sm:bg-transparent px-[3px] py-[2px] ${gradientBg} h-[150px] sm:h-[auto] rounded-[15px]
                            ${isKeyboardVisible ? "absolute -left-[9999px]" : ""}
                            `}>
              <div className="flex flex-col sm:gap-4 sm:bg-transparent bg-[#111820] rounded-[15px] h-full">
                
                <InputTextArea
                  isKeyboardVisible={isKeyboardVisible}
                  prompt={prompt}
                  setPrompt={setPrompt}
                  handleEditClick={() => handleGenerateClick(handleEditMask, prompt)}
                  placeholder="텍스트를 입력하여 선택한 영역에 수정을 해보세요."
                  onMouseDown={() => handleFocusTextArea('#edit-areaEdit-input')}
                />
                <div className="flex justify-end w-full sm:w-auto p-[5px] sm:hidden">
                  <AreaEditButton
                    text="선택한 영역 수정하기"
                    handleGenerateClick={() => handleGenerateClick(handleEditMask, prompt)}
                    isGenerating={isGenerating}
                    progress={progress}
                    btnId="edit-areaEdit"
                  />
                </div>

              </div>
            </div>
            <div className="hidden sm:block">
              <GradientOutlineButton text="선택한 영역 수정하기"
                btnId="edit-areaEdit"
                leftIcon={<FaCoins className="text-[12px] sm:text-[24px] align-middle" />}
                disabled={prompt.trim() === '' || lines.length === 0}
                activating={isGenerating}
                actText='생성중...'
                handleClick={() => handleGenerateClick(handleEditMask, prompt)}/>
            {/* <GradientOutlineButton text="선택한 영역 지우기" disabled={lines.length === 0} handleClick={handleEraseClick}/> */}
            </div>
            {/* <div className="sm:flex sm:flex-col gap-2 sm:gap-4 grid grid-cols-2 hidden sm:block "> */}
            <div className="sm:flex sm:flex-col gap-2 sm:gap-4 hidden">

              <h3 className={h3style}>영역 편집</h3>
              <OutlineButton text="실행 취소" handleClick={lineUndo} disabled={!(historyStep > 0)} leftIcon={<Undo2 size={24} />} btnId="edit-area-undo" />
              <OutlineButton text="다시 실행" handleClick={lineRedo} disabled={!(historyStep < history.length - 1)} leftIcon={<Redo2 size={24} />} btnId="edit-area-redo" />
            </div>
          </div>
        )}
        {/* 텍스트 */}
        {activeTab === 'text' && (
          <div className={`sm:flex sm:flex-col gap-2 sm:gap-4 ${!isKeyboardVisible ? 'grid grid-cols-2' : 'pr-[10px]'}`}>

            {!isKeyboardVisible && <h3 className={h3style}>레이어 추가/제거</h3>}
            {!isKeyboardVisible && (<OutlineButton text="텍스트 추가" handleClick={addTextLayer} btnId="edit-textadd" disabled={images.filter(i => i.id.split('-')[0] === 'text').length >= 10}/>)}
            {!isKeyboardVisible && (<OutlineButton text="텍스트 제거" handleClick={deleteLayer} disabled={!selectedId} btnId="edit-textdelete" />)}
            
            {/* 모바일에서 키보드 올라올 시 인풋창 출력 */}
            <div className={isKeyboardVisible ? "" : "absolute -left-[9999px]"}>
              <InputBtn
                inputValue={textValue}
                handleGenerateClick={addTextLayer}
                placeholder="텍스트 입력"
                onChange={e => handleInputChange('text', e.target.value)}
                btnColor='bg-transparent'
                btnBorder='border-purple-500 border-[2px]'
                btnId="edit-textadd"
                inputId="edit-textadd-input"
                icon={Type}
              />
            </div>            
            {/* 데스크탑 모드일 때 텍스트 에어리어 출력 */}
            <div className={`mb-2 col-span-2 ${isKeyboardVisible ? "absolute -left-[9999px]" : ""}`}>
              <div className={`flex flex-col sm:gap-4 sm:bg-transparent px-[3px] py-[2px] ${gradientBg} sm:h-[auto]
                ${isKeyboardVisible ? 'rounded-[50px] h-[auto]' : 'rounded-[15px] h-[100px]'}`}>
                <div className={`${isKeyboardVisible ? `flex flex-row h-[34px] rounded-[50px]`
                  : `flex flex-col sm:gap-4 sm:bg-transparent bg-[#111820] rounded-[15px] h-full`}`}>
                  <div className="flex justify-center items-center w-full h-full bg-[#24303F] rounded-[15px]">
                    <textarea 
                      className={`w-full sm:h-[100px] h-full sm:text-[16px] text-[16px] text-white placeholder-[#717171] bg-[#111820] outline-none px-[15px] pt-[9px] box-border resize-none
                        ${isKeyboardVisible ? 'pt-[4.5px] bg-[#24303F] rounded-[50px]' : 'bg-[#111820] rounded-[15px]' }`}
                      ref={inputRef}
                      type="text"
                      value={textValue}
                      onChange={e => handleInputChange('text', e.target.value)}
                      onMouseDown={() => handleFocusTextArea('#edit-textadd-input')}
                      placeholder="텍스트 입력"
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isKeyboardVisible && <h3 className={h3style}>레이어 순서변경</h3>}
            {!isKeyboardVisible && <OutlineButton text="레이어를 앞으로" handleClick={moveLayerForward} disabled={!selectedId} btnId="edit-text-gofront" />}
            {!isKeyboardVisible && <OutlineButton text="레이어를 뒤로" handleClick={moveLayerBackward} disabled={!selectedId} btnId="edit-text-goback" />}

            <div className="hidden sm:flex sm:flex-col sm:gap-4">
              {!isKeyboardVisible && <h3 className={h3style}>실행 변경</h3>}
              {!isKeyboardVisible && <OutlineButton className="sm:block hidden" text="실행 취소" handleClick={undo} disabled={undoStack.length === 0}
                leftIcon={<Undo2 className="text-[12px] sm:text-[24px] align-middle" />} btnId="edit-text-undo" />}
              {!isKeyboardVisible && <OutlineButton className="sm:block hidden" text="다시 실행" handleClick={redo} disabled={redoStack.length === 0}
                leftIcon={<Redo2 className="text-[12px] sm:text-[24px] align-middle"/>} btnId="edit-text-redo" />}
            </div>
          </div>
        )}
        {activeTab === 'export' && (
          <div className="sm:flex sm:flex-col gap-2 sm:gap-4 grid grid-cols-2">
            <h3 className={h3style}>내보내기</h3>
            {!isSafari && (
              <OutlineButton text="복사하기" handleClick={handleCopy} btnId="edit-copy" />
            )}
            <OutlineButton text="다운로드" handleClick={handleDownload} btnId="edit-download" />
          </div>
        )}
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />
    </div>
  );
};

const h3style = "text-lg font-semibold text-center text-[#6E95F8] text-[20px] sm:block hidden";

export default SideController;