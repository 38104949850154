import React, { useState, useEffect } from 'react';
import AdminNavBar from '../components/adminNavBar';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { handleAxios } from '../components/modules/AxiosModule';
import { ModalMsg } from '../components/modals/AlertModal';
import LoadingIndicator from '../components/LoadingIndicator';
import config from '../config';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';

const AdminDashboard = () => {

  const apiURL = config.ADMIN_GET_USERS;

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser, authloading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      alert(ModalMsg().wrongAccess);
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const storedToken = JSON.parse(localStorage.getItem('token'));
        
        if (storedToken && storedToken.token) {
          const response = await handleAxios("get", apiURL);
          if (Array.isArray(response.data)) {
            setUsers(response.data);
          } else {
            console.error('Data is not an array', response.data);
          }
        } else {
          console.error('No token found');
        }
      } catch (error) {
        console.error('Fetching users failed:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [apiURL]);

  //////////////////////////////////
  const tools = {
    is_generate_enabled:"생성", 
    is_combine_enabled:"조합", 
    is_canvas_enabled:"캔버스", 
    is_studio_enabled:"스튜디오"
  };

  // users 에서 해당 컬럼만 추출
  const usageData = users.map((
    { is_generate_enabled, is_combine_enabled, is_canvas_enabled, is_studio_enabled }) => ({
      is_generate_enabled,
      is_combine_enabled,
      is_canvas_enabled,
      is_studio_enabled
  }));

  const usageChartData = [
    {
      title: '기능 별 사용자 수(1회 이상)',
      chartData: Object.keys(tools).map(t => ({
        tool: tools[t], //
        amount: usageData.reduce((acc, obj) => acc + (obj[t] === true ? 1 : 0), 0) //true인 경우 +1
      }))
    },
  ];

  function BarChartCard({ title, value, chartData }) {
    return (
      <div className="flex-1 border border-gray-200 rounded-lg p-4 bg-white">
        {/* 상단 영역 */}
        <div className="mb-2">
          <div className="text-xl font-bold">{value}</div>
          <div className="text-base">{title}</div>
        </div>
        {/* 하단 Bar Chart */}
        {/* 높이는 이곳에서 조절 */}
        <div className="w-full h-96">
          <ResponsiveContainer>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="tool" tickMargin={12}/>
              <YAxis scale="linear" domain={[0, 'dataMax']} tickCount={6} tickMargin={12}/>
              <Tooltip 
                formatter={(value) => [`${value}`, '']}
                labelFormatter={(label) => `${label}`} 
              />
              <Bar dataKey="amount" fill="#8884d8" barSize={32} radius={[8, 8, 0, 0]}/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-[150px]">
      <AdminNavBar />
      {isLoading && <LoadingIndicator />}
      <div className="mx-auto mt-[50px] w-11/12">
        <div className="flex justify-between items-center mb-2">
          {/* 너비는 이곳에서 조절 */}
          <div className="flex w-full md:w-1/2 gap-4 m-2 md:m-4">
            {usageChartData.map((item, idx) => (
              <BarChartCard
                key={idx}
                title={item.title}
                value={item.value}
                chartData={item.chartData}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;