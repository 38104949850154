import React, { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';

const ConfirmModal = ({ children, isOpen, setIsOpen, onClose, onConfirm, yesButtonId, noButtonId }) => {
  // 뒤로가기 방지 및 모달 닫기
  useEffect(() => {
    const handlePopState = () => {
      if (isOpen) {
        onClose();
      }
    };

    if (isOpen) {
      window.history.pushState({ modalOpen: true }, '', window.location.href); // 가짜 스택 추가
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
      if (isOpen && window.history.state?.modalOpen) {
        window.history.back(); // 클린업에서 스택 제거
      }
    };
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose} // 바깥 영역 클릭 시 모달 닫기
    >
      <div
        className="relative bg-white rounded-lg p-6 pt-12 w-[550px] text-center shadow-lg"
        onClick={(e) => e.stopPropagation()} // 모달 내부 클릭은 이벤트 전파 차단
      >
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={onClose}
        >
          <IoClose size={24} />
        </button>

        { children }
        <div className="flex justify-center space-x-4">
          <button
            onClick={onClose}
            className="w-24 bg-gray-300 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-400"
            id={noButtonId}
          >아니오</button>
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="w-24 px-4 py-2 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-white hover:from-purple-600 hover:to-blue-600"
            id={yesButtonId}
          >예</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
