import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import SignupModal from '../components/modals/SignupModal';
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';
import { AgreementModal } from '../components/modals/Modal';
import { signupComplete } from '../components/modules/signupFunctions';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null); // 현재 로그인 유저 상태
  const [authloading, setAuthLoading] = useState(true); // 인증 로딩 상태
  const [guestWater, setGuestWater] = useState('') // 비회원 이미지

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [isAgree, setIsAgree] = useState(true);
  const [requestData, setRequestData] = useState({});

  const navigate = useNavigate()

  const loginCheck = ({ redirectPath = '/', showAlert = true, disableRedirect = false } = {}) => {
    if (!authloading && !currentUser) {
      if (showAlert) {
        setShowModal(true);
        // setIsModalOpen(true);
      }
      if (!disableRedirect && redirectPath) {
        navigate(redirectPath);
      }
      return false;
    }
    return true;
  };

  // 로그인 완료시 
  const setGoogleUser = (userData) => {
    const isAdmin = userData.isAdmin;
    setCurrentUser({
      ...userData,
      provider: 'google',
      isAdmin,
    });
  };
  
  // 로그아웃
  const logout = async () => {
    setAuthLoading(true)
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      setCurrentUser(null);
      navigate('/')
    } catch (error) {
      console.error("로그아웃 에러:", error);
    }
    window.location.reload();
    setCurrentUser(null);
    setAuthLoading(false)
  };

  const deleteAccount = async () => {
    const apiUrl = config.USER_DELETE;
    await axios.delete(apiUrl, {
      headers: {
        'Authorization': `Bearer ${currentUser.token}`,
        'ngrok-skip-browser-warning': 'true',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
    localStorage.removeItem('token');
    setCurrentUser(null);
    navigate('/');
    if (window.confirm('탈퇴가 완료되었습니다.')) {
      window.location.reload();
    }
  }

  const setMyToken = (google, response) => {
    const isAdmin = response.data.role === "admin";
    const myToken = {
      token: response.data.access_token,
      expire: Date.now() + 60 * 60 * 18000,
      sub: google.sub || response.sub || currentUser.email ,
      email: google.email || response.email || currentUser.email,
      nickname: response.data.nickname,
      picture: response.data.picture,
      isAdmin,
      name: response.data.name,
      phone: response.data.phone,
      occupation: response.data.occupation,
      adsConsent: response.data.ad_consent,
      contentUse: response.data.content_use,
    }
    localStorage.setItem('token', JSON.stringify(myToken));
    setGoogleUser(myToken);
  }

  const loginRequestData = (user) => {
    const imgUuid = sessionStorage.getItem('imgUuid'); // 세션스토리지에서 imgUuid 확인
  
    // 기본 로그인 데이터 생성
    const data = {
      sub: user.sub,
      email: user.email,
      name: user.name,
      picture: user.picture,
    };
  
    // imgUuid가 있을 경우 uuid 필드 추가
    if (imgUuid) {
      data.uuid = imgUuid;
    }
  
    return data;
  };


  // 구글 로그인 시작
  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => handleLoginSuccess(tokenResponse),
    onError: error => console.error('Login Failed:', error),
  });

  // 구글 로그인 성공시 로직
  const handleLoginSuccess = async (googleResponse) => {
    if (typeof googleResponse.access_token !== 'string' || !googleResponse.access_token) {
      console.error('Invalid or empty token:', googleResponse.access_token);
      return;
    }
    const userInfoEndpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';
    try {
      const userInfoResponse = await axios.get(userInfoEndpoint, {
        headers: {
          'Authorization': `Bearer ${googleResponse.access_token}`
        }
      });
      const data = loginRequestData(userInfoResponse.data)

      // 로그인 서버 엔드 포인트
      const endpoint = config.LOGIN_ENDPOINT;
      const axiosResponse = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        }
      });
      if (axiosResponse.data.over14 === true) {
        setMyToken(userInfoResponse.data, axiosResponse)
        setGuestWater(axiosResponse.data.showWatered)
        sessionStorage.removeItem('imgUuid');
        signupComplete(axiosResponse);
      }
      else {
        setIsAgree(false);
        setRequestData(axiosResponse.data);
        sessionStorage.removeItem('imgUuid');
      }
    } catch (error) {
      console.error('Error fetching user info or processing login:', error);
    }
  };

  // expire에 따른 토큰 리프레쉬
  useEffect(() => {
    const refreshToken = async (currentUser) => {
      try {
        const data = loginRequestData(currentUser)
        const endpoint = config.LOGIN_ENDPOINT;
        const response = await axios.post(endpoint, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        });
        setMyToken(response)
      } catch (error) {
        console.error("토큰 갱신 실패:", error);
        await logout();
      }
      setAuthLoading(false);
    };
  
    const checkTokenAndAuthState = () => {
      const storedUser = localStorage.getItem('token');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        if (Date.now() > parsedUser.expire) {
          refreshToken(parsedUser);
        } else {
          setCurrentUser(parsedUser);
          setAuthLoading(false);
        }
      } else {
        const unsubscribe = onAuthStateChanged(auth, user => {
          if (user) {
            const userData = {
              ...user,
              isAdmin: user.isAdmin,
            };
            localStorage.setItem('token', JSON.stringify(userData));
            setCurrentUser(userData);
          } else {
            setCurrentUser(null);
          }
          setAuthLoading(false);
        });
        return () => unsubscribe();
      }
    };
  
    checkTokenAndAuthState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <AuthContext.Provider value={{
      currentUser,
      setGoogleUser,
      logout,
      authloading,
      googleLogin,
      deleteAccount,
      loginCheck,
      setMyToken,
      loginRequestData,
      guestWater,
      setGuestWater
      }}>
      {/* <Modal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}}>{ ModalMsg().needLogin }</Modal> */}
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>
      {!isAgree && 
        <AgreementModal 
          setIsAgree={setIsAgree}
          requestData={loginRequestData(requestData)}
        />
      }

      <div className='flex md:text-base text-sm mx-auto items-center'>
        <SignupModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          message="서비스를 이용하시려면"
          message1="로그인/회원가입이 필요합니다."
          message2="로그인/회원가입 하시겠습니까?"
          onConfirm={() => {
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;
          const isInAppBrowser = /Instagram|FBAN|FBAV|FB_IAB|FBIOS|FB4A|FBSV|Twitter|Line|KAKAOTALK|NAVER|Naver|Firefox|FxiOS/i.test(userAgent);
          if (isInAppBrowser && /iPhone|iPad|iPod/i.test(userAgent)) {
            setShowModal(false);
            setIsOpen(true);
            setModalMsg(ModalMsg().iosInApp);
          }
          else {
            googleLogin();
            setShowModal(false);
            }
          }}
        />    
      </div>
      {children}
    </AuthContext.Provider>
  );
}

