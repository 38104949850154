import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';

function AdminNavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const menuItems = [
    { label: '회원정보', path: '/admin/memberlist' },
    { label: '대시보드', path: '/admin/dashboard' },
    { label: '히스토리', path: '/admin/history' },
    // { label: '생성하기', path: '/admin/prompt' },
    // { label: '조합하기', path: '/admin/reference' },
    { label: '텍스트생성', path: '/admin/prompthistory' },
    { label: '랜딩샘플', path: '/admin/sample' },
    { label: '좋아요', path: '/admin/fablike' },
    { label: '건의', path: '/admin/fabsuggestion' },
    { label: '오류', path: '/admin/fabreport' },
  ];

  const isActive = (path) => location.pathname === path;

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  // 메뉴 밖 클릭시 닫음
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMobileMenu();
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <div className="fixed top-[48px] md:top-[56px] z-[1300] w-full h-[74px] bg-[rgba(17,24,32,.8)] flex justify-between items-center px-4 box-border select-none">
      <button className="md:hidden text-white text-3xl cursor-pointer"
              onClick={toggleMobileMenu}
      >
        ☰
      </button>

      <div className="flex md:justify-center items-center w-full">
        <div ref={menuRef} className={`fixed top-16 left-0 w-full md:static md:w-auto bg-[rgba(17,24,32,0.95)] z-40 p-5 md:p-0 md:flex md:flex-row md:items-center space-x-0 md:space-x-10 text-white justify-center transition-all duration-300 ${
          isMobileMenuOpen ? 'flex flex-col' : 'hidden md:flex'
        }`} >
          {isMobileMenuOpen && (
            <div className="flex justify-end w-full pr-2">
              <button className="text-white hover:text-gray-400 transition duration-200" onClick={closeMobileMenu}>
                <X className="w-6 h-6" />
              </button>
            </div>
          )}

          {menuItems.map((item) => (
            <div key={item.path}
              className={`text-lg md:text-xl my-2 md:my-0 transition-transform duration-200 cursor-pointer ${
                isActive(item.path) ? 'font-semibold underline scale-105 text-shadow-lg' : ''
              }`}
              onClick={() => {
                navigate(item.path);
                closeMobileMenu();
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminNavBar;