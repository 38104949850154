import React, { useEffect, useState } from 'react';
import AdminNavBar from '../components/adminNavBar';
import config from '../config';
import '../styles/admin.css'
import { handleAxios } from '../components/modules/AxiosModule';
import LoadingIndicator from '../components/LoadingIndicator';
import { ModalMsg } from '../components/modals/AlertModal';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const AdminPromptHistory = () => {
  const navigate = useNavigate()
  const { currentUser, authloading } = useAuth()
  const apiURL = config.GET_GEN_IMAGELIST;
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAscending, setIsAscending] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  const [landingSample, setLandingSample] = useState([]);

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      alert(ModalMsg().wrongAccess);
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  //프롬프트 히스토리 api 호출 함수
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      try {
        const storedToken = JSON.parse(localStorage.getItem('token'));
        
        if (storedToken && storedToken.token) {
          const response = await handleAxios("get", apiURL);

          if (Array.isArray(response.data)) {
            setImages(response.data);
            // console.log('Received data:', response.data);
          } else {
            console.error('Data is not an array', response.data);
          }
        } else {
          console.error('No token found');
        }
      } catch (error) {
        console.error('Fetching users failed:', error);
      }
    };

    fetchUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //갤러리 리스트 api 호출 함수
  //타입 맞춰야함.
  const fetchGallery = async () => {
    setIsLoading(true);

    try {
      const storedToken = JSON.parse(localStorage.getItem('token'));
      
      if (storedToken && storedToken.token) {
        const sampleUrl = config.GET_SAMPLE_IMAGES;
        const response = await handleAxios("get", sampleUrl);

        if (Array.isArray(response.data)) {
          setLandingSample(response.data);
          // console.log('Received data:', response.data);
        } else {
          console.error('Data is not an array', response.data);
        }
      } else {
        console.error('No token found');
      }
    } catch (error) {
      console.error('Fetching gallery failed:', error);
    }
  };
  
  useEffect(() => {
    fetchGallery();
  }, []);

  // 해당 이미지 갤러리에 추가
  const addToSample = async (image) => {
    const addUrl = config.ADMIN_ADD_SAMPLE;
    if (landingSample.includes(image)) {
      alert("이미 샘플에 존재합니다!");
      return;
    }
    try {
      const storedToken = JSON.parse(localStorage.getItem('token'));
        
        if (storedToken && storedToken.token) {
          const requestData = {
            prompt: image.prompt,
            url: image.url,
          };
          const response = await handleAxios("post", addUrl, requestData);
          if (response.data) {
            alert("성공적으로 샘플에 추가되었습니다!");
          }else if (response.data && response.data.message) {
            alert(response.data.message);
          }
        } else {
          console.error('No token found');
        }
      fetchGallery(); // 추가 후 PromptScrolls 데이터 다시 불러오기
      setLandingSample(currentData => currentData.filter(item => item !== image.url));
    } catch (error) {
      alert("추가하는 도중 오류가 발생했습니다: ", error);
    }
  };

  // 해당 이미지 갤러리에서 제거
  const removeFromSample = async (url) => {
    const isConfirmed = window.confirm("정말로 삭제하시겠습니까?");
    if (!isConfirmed) {
      return; // 사용자가 취소하면 함수를 종료합니다.
    }
  
    const removeUrl = config.ADMIN_REMOVE_SAMPLE;
    const requestData = {
      url: url,
    };
    
    const response = await handleAxios("post", removeUrl, requestData);
    if (response.data) {
      alert("성공적으로 샘플에서 제거되었습니다!");
    }else if (response.data && response.data.message) {
      alert(response.data.message);
    }
    // 상태 업데이트로 UI 반영
    fetchGallery();
    setLandingSample(currentData => currentData.filter(item => item !== url));
  };

  useEffect(() => {
    if (images.length === 0) {
      setIsLoading(false);
    }
  }, [images]);

  useEffect(() => {
    if (imagesLoaded === images.length && images.length > 0) {
      setIsLoading(false);
    }
  }, [imagesLoaded, images]);

  useEffect(() => {
    setIsLoading(false);
  }, [landingSample]);


  //정렬 순서 변경. 오름차순,내림차순
  const toggleSortOrder = () => {
    setIsLoading(true);
    setIsAscending(!isAscending);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleImageLoad = () => {
    setImagesLoaded(prevCount => prevCount + 1);
  };

  const handlePageChange = (pageNumber) => {
    setIsLoading(true);
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'auto' });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };
  
  const sortedImages = isAscending ? images : images.slice().reverse();
  const indexOfLastImage = currentPage * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = sortedImages.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(images.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageRange = 2; // 현재 페이지 앞뒤로 보여줄 페이지 수

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPages = [1, 2];
      const endPages = [totalPages - 1, totalPages];

      // Add start pages
      for (let i = 0; i < startPages.length; i++) {
        pageNumbers.push(startPages[i]);
      }

      // Add middle pages with "..." separators
      if (currentPage <= 5) {
        for (let i = 3; i <= 7; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
      } else {
        if (currentPage > pageRange + 2) {
          pageNumbers.push("...");
        }
        for (let i = Math.max(currentPage - pageRange, 3); i <= Math.min(currentPage + pageRange, totalPages - 2); i++) {
          pageNumbers.push(i);
        }
        if (currentPage < totalPages - pageRange - 1) {
          pageNumbers.push("...");
        }
      }

      // Add end pages
      for (let i = 0; i < endPages.length; i++) {
        pageNumbers.push(endPages[i]);
      }
    }

    return pageNumbers;
  };

  //상태 타입 맞춰야함.
  return (
    <div className='adminMembers'>
      <AdminNavBar />
      {isLoading && (
        <LoadingIndicator />
      )}

      <div className='entire-history'>
        <div className='sort-buttons'>
          <button onClick={toggleSortOrder}
            style={{ width: 200, height: 40, margin: 10, borderRadius: 15, fontSize: 17, color: 'white'}}>
            {isAscending ? '내림차순으로 보기' : '오름차순으로 보기'}
          </button>
        </div>
        <div className='images-container'>
          {currentImages.map((image, index) => (
            <div className={landingSample.some(item => JSON.stringify(item) === JSON.stringify({prompt: image.prompt, url: image.url}))
             ? 'highlight relative' : 'relative'}
                key={index}>
              <img src={image.url} alt={`${index}`} onLoad={handleImageLoad} />
              <div className='absolute bottom-10 left-0 w-full text-white text-center py-1 px-2
                        text-[14px] truncate overflow-hidden whitespace-nowrap
                        bg-black bg-opacity-50'>
                <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap block">
                  {image.prompt}
                </p>
              </div>
              <div className='w-full p-2 flex justify-center'>
                {landingSample.some(item => JSON.stringify(item) === JSON.stringify({prompt: image.prompt, url: image.url}))
                ? (
                <button className="mx-auto text-white"
                        onClick={() => removeFromSample(image.url)}>제거</button>
                ) : (
                  <button className="mx-auto text-white"
                          onClick={() => addToSample(image)}>
                    추가</button>
                )}
              </div>
            </div>
          ))}
          
        </div>
        <div className='pagination'>
          {renderPageNumbers().map((number, index) => (
            <button
              key={index}
              onClick={() => typeof number === 'number' && handlePageChange(number)}
              className={number === currentPage ? 'active' : ''}
              disabled={number === "..."}
            >
              {number}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminPromptHistory;
