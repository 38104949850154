import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import firestore from '../firebaseConfig';
import AdminNavBar from '../components/adminNavBar';
import LoadingIndicator from '../components/LoadingIndicator';

const AdminFabSuggestion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fabSuggestions, setFabSuggestions] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 20;

  // Firestore에서 데이터 가져오기
  const fetchFabSuggestions = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const collectionRef = collection(firestore, 'fabSuggestion');
      let q = query(collectionRef, orderBy("createdAt", "desc"), limit(itemsPerPage));

      if (pageNumber > 1 && lastDoc) {
        q = query(collectionRef, orderBy("createdAt", "desc"), startAfter(lastDoc), limit(itemsPerPage));
      }

      const querySnapshot = await getDocs(q);
      const newFabSuggestions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setFabSuggestions(newFabSuggestions);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setCurrentPage(pageNumber);

      if (pageNumber === 1) {
        const totalDocs = await getDocs(collectionRef);
        setTotalPages(Math.ceil(totalDocs.size / itemsPerPage));
      }
    } catch (error) {
      console.error('Error fetching fabSuggestion data:', error);
    }
    setIsLoading(false);
  };

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber) => {
    setIsLoading(true);
    fetchFabSuggestions(pageNumber);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  // Firestore Timestamp 변환 함수 (toLocaleString 사용)
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "날짜 없음";
    return timestamp.toDate().toLocaleString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Seoul",
    });
  };

  useEffect(() => {
    fetchFabSuggestions(1);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <AdminNavBar />
      {isLoading && <LoadingIndicator />}

      {/* 리스트 영역 */}
      <div className="md:mx-80 mt-32">
        <div className="p-4 space-y-6">
          {fabSuggestions.map((suggestion) => (
            <div key={suggestion.id} className="p-4 bg-gray-800 rounded-lg shadow-md border border-gray-700">
              <p className="text-lg font-semibold">
                {suggestion.name || '비회원'} 
                <span className="text-sm text-gray-400"> ({suggestion.email})</span>
              </p>

              <p className="text-sm text-gray-300">{suggestion.sub}</p>

              <p className="mt-2 text-sm text-gray-400">{formatTimestamp(suggestion.createdAt)}</p>

              <p className="mt-4 text-white whitespace-pre-line">{suggestion.context}</p>
            </div>
          ))}
        </div>

        {/* 페이지네이션 (숫자 버튼) */}
        {totalPages > 1 && (
          <div className="flex justify-center space-x-2 my-6">
            {[...Array(totalPages).keys()].map((_, index) => {
              const pageNumber = index + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={`px-4 py-2 rounded-lg ${
                    currentPage === pageNumber ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                  } hover:bg-blue-700 transition duration-300`}
                >
                  {pageNumber}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminFabSuggestion;