import React, { useState, useEffect } from 'react';
import AdminNavBar from '../components/adminNavBar';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { handleAxios } from '../components/modules/AxiosModule';
import { ModalMsg } from '../components/modals/AlertModal';
import LoadingIndicator from '../components/LoadingIndicator';

const AdminMemberList = () => {
  const apiURL = config.ADMIN_GET_USERS;

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });

  const { currentUser, authloading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      alert(ModalMsg().wrongAccess);
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      try {
        const storedToken = JSON.parse(localStorage.getItem('token'));
        
        if (storedToken && storedToken.token) {
          const response = await handleAxios("get", apiURL);

          if (Array.isArray(response.data)) {
            setUsers(response.data);
          } else {
            console.error('Data is not an array', response.data);
          }
        } else {
          console.error('No token found');
        }
      } catch (error) {
        console.error('Fetching users failed:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [apiURL]);

  const handleUserClick = async (user) => {
    const apiUrl = config.ADMIN_IMAGES_BY_SUB;
    try {
      const storedToken = JSON.parse(localStorage.getItem('token'));
      if (storedToken && storedToken.token) {
        const response = await handleAxios("get", apiUrl+user.sub);
        navigate('/admin/userpage', {state: {fetchedData: response.data, userData: user}})
      }
    } catch (error) {
      console.error('Error fetching data for sub:', user.sub, error);
    }
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  // 유저 정보 CSV로 export
  const exportToCSV = () => {
    if (users.length === 0) {
      alert("No user data to export");
      return;
    }
  
    // 우선순위 필드
    const priorityFields = ["nickname", "sub", "email", "created_at", "last_login"];
  
    // 모든 필드 추출
    const allKeysSet = new Set();
    users.forEach(user => {
      Object.keys(user).forEach(key => allKeysSet.add(key));
    });
  
    // 정렬: 우선 필드 + 나머지 필드
    let allKeys = Array.from(allKeysSet);
  
    // picture 필드 제외
    allKeys = allKeys.filter(key => key !== "picture");
  
    const sortedKeys = [...priorityFields];
  
    allKeys.forEach(key => {
      if (!priorityFields.includes(key)) {
        sortedKeys.push(key);
      }
    });
  
    // Header: 첫 글자 대문자
    const headers = sortedKeys.map(key => key.charAt(0).toUpperCase() + key.slice(1));
  
    // Row 데이터
    const rows = users.map(user => {
      return sortedKeys.map(key => `"${user[key] || ''}"`);
    });
  
    // CSV 문자열 생성
    let csvContent = "data:text/csv;charset=utf-8," 
      + headers.join(",") 
      + "\n"
      + rows.map(e => e.join(",")).join("\n");
  
    const encodedUri = encodeURI(csvContent);
  
    // 다운로드 트리거
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="mt-[150px]">
      <AdminNavBar />
      {isLoading && <LoadingIndicator />}
      <div className="mx-auto mt-[50px] w-11/12">
        <div className="flex justify-between items-center mb-2">
          <div className="text-white text-lg ml-4">
            총 회원: {users.length}명
          </div>
          <button
            onClick={exportToCSV}
            className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300"
          >
            Export CSV
          </button>
        </div>

        <table className="table-auto w-full border border-gray-300 rounded-lg overflow-hidden">
          <thead className="bg-gray-800">
            <tr className="bg-[#f4f4f4d6] text-[#333333]">
              {users[0] && Object.keys(users[0]).map((key, index) => 
                // 테이블 헤드에서 첫칸 비움.
                key === 'picture' ? (<th key={key} ></th>) : (
                  <th 
                    key={key} 
                    className={"border border-gray-300 p-3 text-left "
                              // 모바일에서 4번째 컬럼까지만 노출
                              + (index > 3 ? "hidden md:table-cell " : " ")
                              + "cursor-pointer hover:bg-[#808080] hover:text-white "}
                    onClick={() => requestSort(key)}
                  >
                  {/* 기능명만 표시 */}
                  {key.split('_').length > 2 ? key.split('_')[1] : key} 
                  {getSortIndicator(key)}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-transparent text-white text-[14px]">
          {sortedUsers.map((user) => (
            <tr
              key={user.sub}
              className="hover:bg-gray-600 cursor-pointer transition duration-300"
              onClick={() => handleUserClick(user)}
            >
              {Object.keys(user).map((key, index) => (
                <td
                  key={key}
                  className={
                    "border border-gray-300 p-[5px] " +
                    (index > 3 ? "hidden md:table-cell " : " ")
                  }
                >
                  {key === "picture" ? (
                    <img
                      src={user[key]}
                      alt={user.nickname}
                      className="w-[40px] h-[40px] rounded-full mx-auto"
                    />
                  ) : typeof user[key] === "boolean" ? (
                    user[key] ? "T" : "F"
                  ) : (
                    user[key]
                  )}
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminMemberList;
