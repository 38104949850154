import { useState, useEffect, useCallback } from "react";
import { useFab } from "../../context/FabContext";
import FabButton from "./FabButton";
import FabMenu from "./FabMenu";
import FabSuggestion from "./FabSuggestion";
import FabBugReport from "./FabBugReport";
import FabLike from "./FabLike";
import { useLocation } from "react-router-dom";

const FabContainer = () => {
  const { isButtonVisible, isMenuVisible, setIsButtonVisible, setIsMenuVisible, isLikeVisible, setIsLikeVisible } = useFab();
  const [selectedMenu, setSelectedMenu] = useState(null); // 선택된 메뉴 상태
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  const excludedRoutes = ["/mycanvas-edit", "/conceptgenerate"];
  const shouldHideFabButton = excludedRoutes.includes(location.pathname) && isMobile === true;

  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const initialHeight = window.innerHeight;

  // 가상 키보드 감지 함수
  useEffect(() => {
    const handleViewportChange = () => {
      if (window.visualViewport.height < initialHeight * 0.75) {
        setIsKeyboardVisible(true);
      } else {
        setIsKeyboardVisible(false);
      }
    };
    window.visualViewport.addEventListener('resize', handleViewportChange);
    return () => {
      window.visualViewport.removeEventListener('resize', handleViewportChange);
    };
  // eslint-disable-next-line
  }, []);

  // 모바일 감지
  const updateMobileStatus = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileRegex = /android|iPad|iPhone|iPod/i;

    if (mobileRegex.test(userAgent.toLowerCase()) || window.innerWidth <= 1023) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    updateMobileStatus();
    window.addEventListener('resize', updateMobileStatus);
    return () => {
      window.removeEventListener('resize', updateMobileStatus);
    };
  }, [updateMobileStatus]);

  const handleClose = () => {
    setIsButtonVisible(true);
    setIsMenuVisible(false);
    setIsLikeVisible(false)
    setSelectedMenu(null);
  };

  const handleMenuSelect = (menu) => {
    setSelectedMenu(menu);
  };

  const renderFabComponent = () => {
    switch (selectedMenu) {
      case "오류제보":
        return <FabBugReport onBack={() => setSelectedMenu(null)} onClose={handleClose} />;
      case "건의사항":
        return <FabSuggestion onBack={() => setSelectedMenu(null)} onClose={handleClose} />;
      default:
        return isMenuVisible ? <FabMenu onMenuSelect={handleMenuSelect} onClose={handleClose} /> : null;
    }
  };

  return (
    (!isKeyboardVisible && <div className="fixed md:bottom-4 md:right-4 bottom-0 right-0 z-[999999]">
      {isButtonVisible && !isLikeVisible && !shouldHideFabButton && <FabButton />}
      {isLikeVisible && <FabLike onClose={handleClose} />}
      {renderFabComponent()}
    </div>
    )
  );
};

export default FabContainer;