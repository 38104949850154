import React, { useEffect, useState } from 'react';
import { Stage, Layer, Image as KonvaImage, Transformer, Line, Rect, Text as KonvaText } from 'react-konva';
import { Undo2, Redo2, CircleSlash } from 'lucide-react';
import '../styles/KonvaCanvas.css'
import ToastBar from './ToastBar';
import { makeNewImage } from './modules/functions';

const EditKonvaCanvas = ({ 
  setImages, images, 
  setUndoStack, undoStack, setRedoStack, redoStack, undo, redo, lineUndo, lineRedo, 
  setSelectedId, selectedId, transformerRef, stageRef, 
  canvasWidth, canvasHeight, 
  activeTab, 
  brushSize, lines, setLines, 
  setHistory, history, setHistoryStep, historyStep,
  setTextValue, setFontSize, 
  setFontFamily, setColor, 
  setAlign, setVerticalAlign,
  setTextBold,
  setTextItalic,
  setTextStroke, 
  setTextStrokeColor,
  inputRef,
  }) => {

  const [toastMessage, setToastMessage] = useState('');

  // HTML 입력 요소 관련 상태
  const [inputValue, setInputValue] = useState('');

  // 클릭 종류 저장
  const [click, setClick] = useState(false);
  const [textClick, setTextClick] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState(null);

  // 이미지 업데이트 시 히스토리에 추가
  const updateImages = (newImages) => {
    setUndoStack([...undoStack, images]);
    setImages(newImages);
    setRedoStack([]);
  };

  // 캔버스 내용물 조작시 상태 저장
  const handleTransformChange = (newAttrs, id) => {
    // newAttrs 객체에서 값이 NaN인 속성을 제외한 새로운 객체 validAttrs 생성
    const validAttrs = Object.keys(newAttrs).reduce((acc, key) => {
      // Number.isNaN()을 사용하면 값이 실제 NaN인지 안전하게 판별할 수 있습니다.
      if (!Number.isNaN(newAttrs[key])) {
        acc[key] = newAttrs[key];
      }
      return acc;
    }, {});

    const newImages = images.map((img) => {
      if (img.id === id) {
        return { ...img, ...validAttrs };
      }
      return img;
    });
    updateImages(newImages);
  };

  // 이미지 클릭하면 해당 이미지 선택
  const handleImageClick = (id) => {
    setSelectedId(id);
  };

  // 선택 해제
  const clearSelection = (e) => {
    // 이미지 레이어나 트랜스포머가 아닌 다른 곳을 클릭했을 때
    if (e.target === e.target.getStage()) {
      setSelectedId(null);
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
  };
  // 트랜스폼 조작 완료 시 호출
  const handleTransformEnd = (imgId, e) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const newX = node.x();
    const newY = node.y();
    const newWidth = node.width();
    const newHeight = node.height();
    // const newFontSize = node.fontSize ?  node.fontSize()* scaleY : undefined;
    // const newWidth = node.width() * scaleX;
    // const newHeight = node.height() * scaleY;

    const newRotation = node.rotation();  // 회전 상태 저장
  
    // node.scaleX(1);
    // node.scaleY(1);

    if(imgId.split('-')[0]==='text') {
      handleTransformChange({
        scaleX: scaleX,
        scaleY: scaleY,
        rotation: newRotation,  // 회전 상태 추가
      }, imgId);
    }
    else {
      handleTransformChange({
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
        // fontSize: newFontSize,
        scaleX: scaleX,
        scaleY: scaleY,
        rotation: newRotation,  // 회전 상태 추가
      }, imgId);
    }
  };

  const handleDragEnd = (e) => {
    const node = e.target;
    const newX = node.x();
    const newY = node.y();
    const newWidth = node.width()*node.scaleX();
    const newHeight = node.height()*node.scaleY();

    // 스테이지의 경계를 계산
    const stageWidth = stageRef.current.width();
    const stageHeight = stageRef.current.height();

    // 이미지가 스테이지 바깥으로 나가는 경우
    let outOfBounds = false;

    if (newX + newWidth < 0 || newX > stageWidth || newY + newHeight < 0 || newY > stageHeight) {
      outOfBounds = true;
    }

    // 스테이지 밖으로 나간 경우, 레이어를 캔버스 가운데로 이동
    if (outOfBounds) {
      const centerX = (stageWidth - newWidth) / 2;
      const centerY = (stageHeight - newHeight) / 2;
      node.position({ x: centerX, y: centerY });
      node.getLayer().batchDraw(); // 캔버스 업데이트

      // 토스트 메시지 표시
      setToastMessage('레이어가 캔버스 밖으로 빠져나가서 가운데 위치로 되돌렸습니다');
    } else {
      // 정상적인 경우, 위치 및 상태 업데이트
      const newImages = images.map(img => {
        if (img.id === node.id()) {
          return { 
            ...img, 
            x: newX, 
            y: newY,
            rotation: node.rotation()  // 회전 상태 저장
          };
        }
        return img;
      });

      setImages(newImages);
    }
  };

  useEffect(() => {
    // activeTab이 변경될 때만 선택된 레이어 해제
    setSelectedId(null);
    if (transformerRef.current) {
        transformerRef.current.nodes([]);
    }
  // eslint-disable-next-line
  }, [activeTab]);

  // 드래그 드랍으로 업로드
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
  
    reader.onload = () => {
      const img = new window.Image();
      img.src = reader.result;
      img.onload = () => {
        const newImageId = `upimg-${Date.now()}`;
        const newImage = makeNewImage({
          canvasWidth: canvasWidth, 
          canvasHeight: canvasHeight,
          image: img, 
          id: newImageId
        });
  
        setUndoStack([...undoStack, images]);
        setImages(prev => [...prev, newImage]);
        setSelectedId(newImageId);
        setRedoStack([]);
      };
    };
  
    if (files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const stage = stageRef.current;
    stage.container().addEventListener('dragover', handleDragOver);
    stage.container().addEventListener('drop', handleDrop);

    return () => {
      stage.container().removeEventListener('dragover', handleDragOver);
      stage.container().removeEventListener('drop', handleDrop);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedId && transformerRef.current) {
      const node = stageRef.current.findOne(`#${selectedId}`);
      if (node) {
        transformerRef.current.nodes([node]);
        transformerRef.current.getLayer().batchDraw();
      } else {
        transformerRef.current.nodes([]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, images]);

  // 브러싱 Brushing 관련
  const isDrawing = React.useRef(false);

  // 브러시 관련 핸들러에 터치 대응 추가
  const handleMouseDown = (e) => {
    if(activeTab === 'brushing'){
      let pos;
      if (e.evt.touches && e.evt.touches.length > 0) {
        const stage = e.target.getStage();
        // const touch = e.evt.touches[0];
        pos = stage.getPointerPosition(); // 스테이지 좌표로 변환
      } else {
        pos = e.target.getStage().getPointerPosition();
      }
      isDrawing.current = true;
      setLines([...lines, { points: [pos.x, pos.y], brushSize: brushSize }]);
    }
  };
  
  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    let point;    
    if (e.evt.touches && e.evt.touches.length > 0) {
      // const touch = e.evt.touches[0];
      point = stage.getPointerPosition(); // 터치 좌표 변환
    } else {
      point = stage.getPointerPosition();
    }
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);    
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = (e) => {
    if (isDrawing.current) {
      addToHistory();
      isDrawing.current = false;
    }
  };

  // 브러시 레이어 삭제
  const deleteBrushLayer = () => {
    const stage = stageRef.current;
    const brushLayer = stage.findOne('.brush');

    setHistory([{ lines: [] }]);
    setHistoryStep(0);

    setLines([]);
    brushLayer.batchDraw();
  };

  useEffect(() => {
    const stage = stageRef.current;
    stage.container().addEventListener('dragover', handleDragOver);
    stage.container().addEventListener('drop', handleDrop);

    return () => {
      stage.container().removeEventListener('dragover', handleDragOver);
      stage.container().removeEventListener('drop', handleDrop);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stageRef.current) {
      const stage = stageRef.current;
      //이름이 brush인 레이어
      const brushLayer = stage.findOne('.brush');
  
      // 모든 레이어
      const allLayers = stage.find('Layer');
            
      if (brushLayer) {
        const konvaContainer = brushLayer.getCanvas()._canvas; // HTMLCanvasElement
  
        // z-index 설정
        konvaContainer.style.zIndex = (activeTab === 'brushing') ? '999' : '-1';
      }
  
      allLayers.forEach(layer => {
        if (layer !== brushLayer) {
          layer.listening(!(activeTab === 'brushing'));
        }
      });
  
      if (activeTab === 'brushing') {
        // 브러싱 모드일 때 마우스 및 터치 이벤트 리스너 추가
        stage.on('mousedown', handleMouseDown);
        stage.on('mousemove', handleMouseMove);
        stage.on('mouseup', handleMouseUp);
        stage.on('touchstart', handleMouseDown);
        stage.on('touchmove', handleMouseMove);
        stage.on('touchend', handleMouseUp);
      } else {
        // 브러싱 모드가 아닐 때 마우스 및 터치 이벤트 리스너 제거
        stage.off('mousedown');
        stage.off('mousemove');
        stage.off('mouseup');
        stage.off('touchstart');
        stage.off('touchmove');
        stage.off('touchend');
  
        deleteBrushLayer();
      }
  
      // 클린업 함수
      return () => {
        stage.off('mousedown');
        stage.off('mousemove');
        stage.off('mouseup');
        stage.off('touchstart');
        stage.off('touchmove');
        stage.off('touchend');
  
        allLayers.forEach(layer => layer.listening(true));
      };
    }
  // eslint-disable-next-line 
  }, [activeTab]);  

  const addToHistory = () => {
    const newHistory = history.slice(0, historyStep + 1);
    newHistory.push({ lines });
    setHistory(newHistory);
    setHistoryStep(newHistory.length - 1);
  };

  // HTML 입력 요소가 보일 때 자동 포커스
  useEffect(() => {
    if (selectedId && inputRef.current) {
      inputRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    setImages(prev =>
      prev.map(item =>
        item.id === selectedId ? { ...item, text: inputValue } : item
      )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [inputValue]);

  // 텍스트 탭에서 스테이지 클릭 시 ( 텍스트 추가 )
  const handleClick = () => {
    transformerRef.current.nodes([]);
    setClick(true);
  }

  // 텍스트 클릭 핸들
  const handleTextClick = (id, e) => {
    e.cancelBubble = true;
    setSelectedId(id);
    setTextClick(true);
  }

  // 입력폼의 값 변경 시, 폼 상태와 선택된 오브젝트(있는 경우)를 업데이트합니다.
  // 배경 색상
  const handleInputChange = (key, value) => {
    // 폼 상태 업데이트
    switch (key) {
      case 'backgroundColor':
        setBackgroundColor(value);
        break;
      default:
        break;
    }
  };

  //텍스트가 비어 있는 텍스트 객체 제거
  useEffect(() => {
    const filteredImages = images.filter(
      (image) => (image.text !== '' || image.id === selectedId) 
    );
    // 만약 필터링 결과가 기존 배열과 길이가 다르다면 상태를 업데이트합니다.
    if (filteredImages.length !== images.length) {
      setImages(filteredImages);
      undo();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[images, selectedId, setImages]);

  // 텍스트 추가를 위한 클릭 동작 감지 시 (텍스트가 클릭되지 않아야 함)
  useEffect(() => {
    if(click===true && textClick===false) {
      if (images.filter(i => i.id.split('-')[0] === 'text').length >= 10) {
        // 토스트 메시지 표시
        setToastMessage('텍스트는 최대 10개까지 추가할 수 있습니다.');
        return;
      }
      if (!selectedId) {
        const stage = stageRef.current.getStage();
        const pointerPos = stage.getPointerPosition();
        if (!pointerPos) return;

        // 새 텍스트 입력을 위한 상태 설정
        setSelectedId(null);
        setInputValue('');

        const newTextId = `text-${Date.now()}`;
        const newText = {
          id: newTextId,
          text: inputValue,
          fontSize: 20,
          fontFamily: 'serif',
          color: '#000000',
          textBold: false,
          textItalic: false,
          textStroke: 0,
          textStrokeColor: '#ffffff',
          align: 'left',
          verticalAlign: 'top',
          x: pointerPos.x, // 필요에 따라 초기 위치를 지정할 수 있습니다.
          y: pointerPos.y,
          width: null,   // 추후에 Konva에서 텍스트 렌더링 후 결정될 수 있음
          height: null,
          rotation: 0,
        };
        // undoStack에 현재 상태(images)를 저장한 후
        setUndoStack([...undoStack, images]);
        // 새로운 텍스트 레이어를 images 배열에 추가
        setImages(prev => [...prev, newText]);
        // 새로 추가된 텍스트를 선택 상태로 설정
        setSelectedId(newTextId);
        // redo 스택은 초기화
        setRedoStack([]);
        setClick(false);
      }
      else {
        setSelectedId(null);
      }
    }

    setClick(false);
    setTextClick(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [click, textClick]);

  // selectedId가 변경되면, 해당 오브젝트의 속성값으로 폼을 업데이트합니다.
  useEffect(() => {
    if (selectedId && selectedId.split('-')[0] ==='text') {
      const selectedObj = images.find(item => item.id === selectedId);
      if (selectedObj) {
        setTextValue(selectedObj.text);
        setFontSize(selectedObj.fontSize);
        setFontFamily(selectedObj.fontFamily);
        setColor(selectedObj.color);
        setAlign(selectedObj.align || 'left');
        setTextBold(selectedObj.textBold);
        setTextItalic(selectedObj.textItalic);
        setTextStroke(selectedObj.textStroke);
        setTextStrokeColor(selectedObj.textStrokeColor);
        setVerticalAlign(selectedObj.verticalAlign || 'top');
      }
    }
    else {
      setTextValue('');
      setFontSize(20);
      setFontFamily('serif');
      setColor('#000000');
      setAlign('left');
      setAlign('left');
      setTextBold(false);
      setTextItalic(false);
      setTextStroke(0);
      setTextStrokeColor('#ffffff');
      setVerticalAlign('top');

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, images]);

  return (
    <div className="EditKonvaCanvas relative"
      onMouseEnter={(e) => {
        if (activeTab === 'brushing') {
          e.currentTarget.classList.add('custom-cursor');
        }
      }}
      onMouseLeave={(e) => {
        if (activeTab === 'brushing') {
          e.currentTarget.classList.remove('custom-cursor');
        }
      }}
    >
      <ToastBar message={toastMessage} duration={3000} setToastMessage={setToastMessage} />
      <Stage
        width={canvasWidth}
        height={canvasHeight}
        ref={stageRef}
        onClick={(activeTab === 'text') ? handleClick :clearSelection}
        onTap={(activeTab === 'text') ? handleClick :clearSelection}
        className='EditKonvaStage'

        onMouseDown= {(activeTab === 'brushing') ? handleMouseDown : undefined}
        onMousemove={(activeTab === 'brushing') ? handleMouseMove : undefined}
        onMouseup={(activeTab === 'brushing') ? handleMouseUp : undefined}
        onTouchStart={(activeTab === 'brushing') ? handleMouseDown : undefined}
        onTouchMove={(activeTab === 'brushing') ? handleMouseMove : undefined}
        onTouchEnd={(activeTab === 'brushing') ? handleMouseUp : undefined}
      >
        <Layer 
          name='background'
          visible={true}
          >
          <Rect
            x={0}
            y={0}
            width={999}
            height={999}
            fill={backgroundColor}
          />
        </Layer>
        <Layer name='images'>
          {images.map((img) => (img.id &&
            img.id.split('-')[0] === 'text' ? (
              <KonvaText
                // ref={textRef}
                key={img.id} // id를 key로 사용
                text={img.text}
                x={img.x}
                y={img.y}
                width={img.width}
                height={img.height}
                scaleX={img.scaleX}
                scaleY={img.scaleY}
                rotation={img.rotation || 0}
                fontSize={img.fontSize}
                fontFamily={img.fontFamily}
                fill={img.color}
                fontStyle={`${img.textBold ? 'bold' : ''} ${img.textItalic ? 'italic' : ''}`}
                fillAfterStrokeEnabled={true}
                stroke={img.textStrokeColor}
                strokeWidth={img.textStroke}
                align={img.align}
                verticalAlign={img.verticalAlign}
                id={img.id}
                wrap='none'
                lineJoin='round'
                draggable={(activeTab === 'layer' || activeTab === 'text') && selectedId === img.id}
                onClick={(activeTab === 'layer' || activeTab === 'text') 
                  ? (e) => handleTextClick(img.id, e) : undefined}
                onTap={(activeTab === 'layer' || activeTab === 'text') 
                  ? (e) => handleTextClick(img.id, e) : undefined}
                onTransformEnd={(e) => handleTransformEnd(img.id, e)}
                onDragStart={(e) => {
                  setUndoStack([...undoStack, images]);
                  setRedoStack([]);
                }}
                onDragEnd={handleDragEnd}
              />
            ):(
              <KonvaImage
                key={img.id} // id를 key로 사용
                image={img.image}
                x={img.x}
                y={img.y}
                width={img.width}
                height={img.height}
                scaleX={img.scaleX}
                scaleY={img.scaleY}
                rotation={img.rotation || 0}
                id={img.id}
                draggable={(activeTab === 'layer') && selectedId === img.id}
                onClick={(activeTab === 'layer') ? () => handleImageClick(img.id) : undefined}
                onTap={(activeTab === 'layer') ? () => handleImageClick(img.id) : undefined}
                onTransformEnd={(e) => handleTransformEnd(img.id, e)}
                onDragStart={(e) => {
                  setUndoStack([...undoStack, images]);
                  setRedoStack([]);
                }}
                onDragEnd={handleDragEnd}
              />
            )
          ))}
          <Transformer 
            ref={transformerRef}
            enabledAnchors={selectedId && selectedId.split('-')[0] === 'text' ? 
              ['top-left', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-right'] : 
              ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']
            }
          />
        </Layer>
        <Layer 
          name='bg'
          visible={false}
          >
          <Rect
            x={0}
            y={0}
            width={999}
            height={999}
            fill="black"
          />
        </Layer>
        <Layer 
          name='brush'
          opacity={0.5}
          >
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke="#a090ff"
              strokeWidth={line.brushSize || brushSize}  // 각 선의 brushSize 또는 현재 brushSize 사용
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation={'source-over'}
            />
          ))}
        </Layer>
      </Stage>
      {activeTab !== 'export' &&
        <div className="absolute right-[-40px] top-0 flex flex-col space-y-2 gap-[10px]">
          <button className="w-[26px] h-[26px] rounded-full p-1 shadow-md border-[1px] border-white"
            id='edit-icon-undo'
            onClick={activeTab === 'layer' || activeTab === 'text' ? undo : lineUndo}
            disabled={activeTab === 'layer' || activeTab === 'text' ? undoStack.length === 0 : historyStep <= 0}>
            <Undo2 className="text-white" size={16} />
          </button>
          <button className="w-[26px] h-[26px] rounded-full p-1 shadow-md border-[1px] border-white"
            id='edit-icon-redo'
            onClick={activeTab === 'layer' || activeTab === 'text' ? redo : lineRedo}
            disabled={activeTab === 'layer' || activeTab === 'text' ? redoStack.length === 0 : historyStep >= history.length - 1}>
            <Redo2 className="text-white" size={16} />
          </button>
          <div />
          <input
          // 여백없애기 위해 index.css에서 color-input을 선언함.
            id='edit-bgColor'
            className="rounded-full w-[26px] h-[26px] p-1 shadow-md border-[1px] border-white color-input"
            type="color"
            value={'#ffffff'}
            onChange={e => handleInputChange('backgroundColor', e.target.value)}
          />
          <button className="rounded-full p-1 shadow-md border-[1px] border-white"
            id='edit-remove-bgColor'
            onClick={() => setBackgroundColor(null)}
          >
            <CircleSlash className="text-white" size={16} />
          </button>
        </div>
      }

    </div>
  );
};

export default EditKonvaCanvas;