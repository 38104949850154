import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GradientOutlineButton, OutlineButton } from './Buttons';
import { FaCoins } from 'react-icons/fa';
import LoadingIndicator from './LoadingIndicator';
import { AlertModal, ModalMsg } from './modals/AlertModal';
import config from '../config';
import { handleAxios } from './modules/AxiosModule';
import { copyImageToClipboard, b64ToCanvas, downloadImage } from './modules/canvasFuntions';
import { urlToB64 } from './modules/functions';
import { useFab } from '../context/FabContext';

const ResultMember = ({ imageData, checkCredits, loginCheck, fetchCredits, isLoading, setIsLoading }) => {
  const { openFabLike, setIsLikeVisible } = useFab();

  const [loadingText, setLoadingText] = useState('')
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  // 재생성 요청
  const handleRegenerate = async () => {
    setIsLikeVisible(false)
    const isLoggedIn = loginCheck({ showAlert: true, disableRedirect: true });
    // 비로그인인 경우
    if (!isLoggedIn) return;
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;

    setLoadingText("로보브러시가 이미지를 만들고 있어요.")
    setIsLoading(true);
    
    try {
      const ReGenApiURL = config.RE_GEN_APIURL;
      const requestData = {
        image_url: imageData.url,
      }
      // console.log(requestData);
      const response = await handleAxios("post", ReGenApiURL, requestData);
      
      if (response.data) {
        // console.log(response.data);
        await fetchCredits();
        navigate('/mycanvas-result', { state: { imageData: response.data } });
        openFabLike(response.data.url)
      } else if (response.data && response.data.message) {
        setModalMsg(response.data.message);
        setIsOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          setModalMsg(ModalMsg().noCredits);
          setIsOpen(true);
          navigate('/pricing');
        } else {
          setModalMsg(ModalMsg().loadingFailed);
          setIsOpen(true);
        }
      } else {
        console.log(error)
        setModalMsg(ModalMsg().loadingFailed);
        setIsOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // 복사
  const handleCopy = async () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    try {
      setLoadingText("이미지를 복사하는 중입니다.");
      setIsLoading(true);
      if (isSafari) {
        // Safari 브라우저인 경우 URL을 텍스트로 클립보드에 복사
        await navigator.clipboard.writeText(imageData.url);
      } else {
        //////////////////// url -> b64 호출
        const b64Data = await urlToB64(imageData.url);
        ///////////////////// b64 -> 가상의 canvas
        const canvas = await b64ToCanvas(b64Data);
        const ref = { current: canvas }
        await copyImageToClipboard(ref);
      }
      setModalMsg(ModalMsg().copySuccess);
      setIsOpen(true);
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // 다운로드
  const handleDownload = async () => {
    try {
      setLoadingText("이미지를 다운로드하는 중입니다.")
      setIsLoading(true);

      /////////////////// url -> b64 호출
      const b64Data = await urlToB64(imageData.url);
      //////////////////// b64 -> canvas
      const canvas = await b64ToCanvas(b64Data);
      const ref = { current: canvas }
      downloadImage(ref);
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  // 편집 시작하기 시 url to b64 for edit
  const handleEditStart = async () => {
    try {
      setLoadingText("AI 캔버스를 로딩중입니다.")
      setIsLoading(true);

      const b64Data = await urlToB64(imageData.url);

      navigate('/mycanvas-edit', { state: { image: {b64image: b64Data, url: imageData.url} }});
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start px-4 mt-20">
      <AlertModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMsg}></AlertModal>

      {isLoading && <LoadingIndicator text={loadingText} />}
      <div className="w-full max-w-[660px] h-auto aspect-square mb-4"
        onContextMenu={(e) => e.preventDefault()} // 우클릭 방지
      >
        <img src={imageData.url} alt="Selected" className="w-full h-full object-contain" />
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2 gap-4 sm:w-[auto] w-[90%]">
          <GradientOutlineButton text="로보브러시로 편집하기" handleClick={handleEditStart} btnId="canvas-result-member-edit" />
          <GradientOutlineButton 
            text="다시 생성하기" 
            disabled={imageData.url.includes("BGC")}
            leftIcon={<FaCoins className="text-[14px] sm:text-[24px] align-middle"/>} 
            handleClick={handleRegenerate} 
            btnId= 'canvas-result-member-regen' />
          <OutlineButton text="복사" handleClick={handleCopy} btnId="canvas-result-member-copy" />
          <OutlineButton text="다운로드" handleClick={handleDownload} btnId="canvas-result-member-down" />
        </div>
      )}
    </div>
  );
};

export default ResultMember;