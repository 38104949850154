import React, { useEffect } from 'react';
import { GradientButton } from '../Buttons';
import { IoClose } from 'react-icons/io5';

const SignupModal = ({ isOpen, setIsOpen, message, message1, message2, onConfirm }) => {
  const Confirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.id === 'overlay') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handlePopState = (e) => {
      if (isOpen) {
        setIsOpen(false); // 뒤로가기로 닫기
      }
    };

    if (isOpen) {
      window.history.pushState({ modalOpen: true }, '', window.location.href); // 가짜 스택 추가
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
      if (isOpen && window.history.state?.modalOpen) {
        window.history.back(); // 클린업에서 스택 제거
      }
    };
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      id="overlay"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]"
      onClick={handleOverlayClick}
    >
      <div className="relative bg-white p-8 m-4 rounded-lg shadow-lg text-center max-w-xl w-full font-bold">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={() => setIsOpen(false)}
        >
          <IoClose size={24} />
        </button>
        <h2 className="text-[5.7vw] sm:text-3xl">{message}</h2>
        {message1 && <h2 className="text-[5.7vw] sm:text-3xl mt-1">{message1}</h2>}
        {message2 && <h2 className="text-[5.8vw] sm:text-4xl mt-6 sm:mt-16">{message2}</h2>}
        <div className="mt-12 sm:mt-24 ml-[auto] mr-[auto] flex justify-center">
          <GradientButton
            btnId="signup-modal-yes"
            text="가입하기"
            mobileFontSize='16px'
            size='w-[220px] h-[42px] sm:w-[270px] sm:h-[50px]'
            handleClick={Confirm}
          />
        </div>
      </div>
    </div>
  );
};

export default SignupModal;