import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../styles/StudioGenerator.css'
import { useAuth } from '../context/AuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import config from '../config'
import { GradientOutlineButton, OutlineButton } from '../components/Buttons';
import { useCredit } from '../context/CreditContext'
import { handleAxios } from '../components/modules/AxiosModule';
import { stripBase64Prefix, makeNewImage } from '../components/modules/functions'
import { AlertModal, ModalMsg } from '../components/modals/AlertModal';
import {SmallLoadingIndicator} from '../components/LoadingIndicator'
import { ImagePlus, Images, CircleX, Upload } from 'lucide-react';
import { FaCoins } from 'react-icons/fa';

import { copyImageToClipboard, b64ToCanvas, downloadImage } from '../components/modules/canvasFuntions';
import { urlToB64 } from '../components/modules/functions';
import { useFab } from '../context/FabContext'
import { gradientBg } from '../components/modules/colors'
import StudioSampleImage from '../components/StudioSampleImage'

const StudioGenerator = () => {
  const { currentUser, loginCheck, authloading } = useAuth();
  const { checkCredits, fetchCredits } = useCredit();
  const { openFabLike, setIsLikeVisible } = useFab();
  const navigate = useNavigate();
  const location = useLocation();
  const [images, setImages] = useState([]);
  const canvasWidth = 512;
  const canvasHeight = 512;
  const fileInputRef = useRef(null);
  const fileInputButtonRef = useRef(null);
  const [analyzedData, setAnalyzedData] = useState([]);
  const [studioId, setStudioId] = useState(-1);
  const [stepAutoGen, setStepAutoGen] = useState('');
  const [resultImg, setResultImg] = useState([]);

  const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [isResultVisible, setIsResultVisible] = useState(false)
  const [isMobile, setIsMobile] = useState(false);
  
  const [isOpen, setIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  // 비로그인시 메인으로
  useEffect(() => {
    loginCheck('/')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, authloading])

  // 모바일 감지
  const updateMobileStatus = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileRegex = /android|iPad|iPhone|iPod/i;

    if (mobileRegex.test(userAgent.toLowerCase()) || window.innerWidth <= 1023) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    updateMobileStatus();
    window.addEventListener('resize', updateMobileStatus);
    return () => {
      window.removeEventListener('resize', updateMobileStatus);
    };
  }, [updateMobileStatus]);

  useEffect(() => {
    if (location.state?.upFile) {
      const img = new Image();
      img.src = location.state.upFile;  // Base64 문자열을 직접 src로 사용
      img.onload = () => {
        const newImageId = `image-${Date.now()}`
        const newImage = makeNewImage({
          canvasWidth: canvasWidth,
          canvasHeight: canvasHeight,
          image: img,
          id: newImageId
        });
        
        setImages([newImage]);
      };
      img.onerror = () => {
        console.error("Failed to load image");
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.upFile]);

  // 이미지 업로드
  const handleImageUpload = (event) => {
    setImages([]);
    setResultImg([]);
    const file = event.target.files[0];
  
    if (!file) return; // 파일이 없을 경우 종료
  
    setIsLoading(true);
    setLoadingText("이미지를 업로드 중입니다.");
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const img = new window.Image();
      img.src = e.target.result;
  
      img.onload = () => {
        const newImageId = `image-${Date.now()}`;
        const newImage = makeNewImage({
          canvasWidth: canvasWidth,
          canvasHeight: canvasHeight,
          image: img,
          id: newImageId,
        });
  
        const creditsAvailable = checkCredits(2);
        if (!creditsAvailable) {
          setIsLoading(false);
          return;
        }
        // 이미지 상태 업데이트
        setImages([newImage]);
        setMessages([msgList[0]]);
        setStepAutoGen("uploaded");
        setIsResultVisible(true);
        setIsLoading(false);
      };
  
      img.onerror = () => {
        console.error("이미지 로드 실패");
        setIsLoading(false);
      };
    };
  
    reader.onerror = () => {
      console.error("파일 읽기 실패");
      setIsLoading(false);
    };
  
    reader.readAsDataURL(file);
    event.target.value = ''; // 파일 선택 초기화
  };

  // 캔버스 클릭시 업로드 창 띄움
  const openFilePicker = () => {
    if(fileInputButtonRef)
      fileInputButtonRef.current.click();
  };

  // 배경 제거
  const handleBackgroundRemoveClick = async () => {
    fetchCredits();
    setLoadingText("로보브러시가 배경을 제거하고 있습니다.");
    setIsLoading(true);
    try {
      const imageDataUrl = images[0].image.src;
      const apiRefURL = config.BG_REMOVE_APIURL;
      const strippedImageData = stripBase64Prefix(imageDataUrl);
      const requestData = {
        my_product: strippedImageData
      }
      const response = await handleAxios("post", apiRefURL, requestData);
      setAnalyzedData(response.data.my_product);
      setStudioId(response.data.studio_id)

      const img = new window.Image();
      img.crossOrigin = "anonymous";
      img.src = `data:image/png;base64,${response.data.image}`;
      img.onload = () => {
        const newImageId = studioId;
        const newImage = makeNewImage({
          canvasWidth: canvasWidth,
          canvasHeight: canvasHeight,
          image: img,
          id: newImageId
        });

        setImages(prev => [...prev, newImage]);
        setStepAutoGen('analyzed');
      };
    } catch (error) {
      setModalMsg(ModalMsg().bgRemoveError);
      setIsOpen(true);
      setStepAutoGen('error');
      setIsLoading(false);
    } finally {
    }
  };
  
  // 생성 요청
  const handleMaskKeyword = async () => {
    setLoadingText("로보브러시가 키워드 추천 중.");
    setIsLoading(true);
    const requestData = {
      studio_id: studioId
    };
    // // console.log('Sending request data:', requestData);
    const apiRefURL = config.MASK_KEYWORD_APIURL;
    try {
      const response = await handleAxios("post", apiRefURL, requestData);

      if (response.data) {
        setStepAutoGen("masked");
      } else if (response.data && response.data.message) {
        setModalMsg(response.data.message);
        setIsOpen(true);
        // alert(response.data.message);
      }
    } catch (error) {
      setStepAutoGen("error");
      setIsLoading(false);
      if (error.response && error.response.status === 400) {
        setModalMsg(ModalMsg().generateFailed);
        setIsOpen(true);
      } else {
        setModalMsg(ModalMsg().generateFailed);
        setIsOpen(true);
      }
    } finally {
    }
  };

  // 생성 요청
  const handleGenerate = async () => {
    setIsLikeVisible(false);
    if (stepAutoGen === '') {
      //재생성 버튼 클릭 시
      resetMessage()
      addMessage(3);
    }
    //크레딧체크하고 부족하면 리턴
    const creditsAvailable = checkCredits(2);
    if (!creditsAvailable) return;
    setLoadingText("로보브러시가 이미지를 만들고 있어요.")
    setIsLoading(true);
    const requestData = {
      studio_id: studioId
    };
    // // console.log('Sending request data:', requestData);
    const apiRefURL = config.STUDIO_APIURL;
    try {
      const response = await handleAxios("post", apiRefURL, requestData);
  
      if (Array.isArray(response.data) && response.data.length === 0) {
        setModalMsg(ModalMsg().NSFW);
        setIsOpen(true);
        setIsLoading(false);
        return;
      } else if (response.data) {
        await fetchCredits();
        setResultImg(response.data)
        addMessage(4);
        setStepAutoGen('');//자동생성단계초기화
        openFabLike(response.data)
      }
    } catch (error) {
      // console.log(error);
      setStepAutoGen('error');
      setIsLoading(false);
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          setModalMsg(ModalMsg().noCredits);
          setIsOpen(true);
          navigate('/pricing')
        } else {
          setModalMsg(ModalMsg().generateFailed);
          setIsOpen(true);
        }
      } else {
        setModalMsg(ModalMsg().generateFailed);
        setIsOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // 드래그 드랍으로 업로드
  const handleDrop = (e) => {
    e.preventDefault();
    setImages([]);
    setResultImg([]);
    const files = e.dataTransfer.files;
    const reader = new FileReader();

    reader.onload = () => {
      const img = new window.Image();
      img.src = reader.result;
      img.onload = () => {
        const newImageId = `image-${Date.now()}`
        const newImage = makeNewImage({
          canvasWidth: canvasWidth,
          canvasHeight: canvasHeight,
          image: img,
          id: newImageId
        });
        setImages([newImage]);
      };
    };

    setMessages([msgList[0]]);
    setStepAutoGen('uploaded');
    setIsLoading(false);

    if (files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // 복사
  const handleCopy = async () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    try {
      setLoadingText("이미지를 복사하는 중입니다.");
      setIsLoading(true);
      if (isSafari) {
        // Safari 브라우저인 경우 URL을 텍스트로 클립보드에 복사
        await navigator.clipboard.writeText(resultImg[0]);
      } else {
        //////////////////// url -> b64 호출
        const b64Data = await urlToB64(resultImg[0]);
        ///////////////////// b64 -> 가상의 canvas
        const canvas = await b64ToCanvas(b64Data);
        const ref = { current: canvas }
        await copyImageToClipboard(ref);
      }
      setModalMsg(ModalMsg().copySuccess);
      setIsOpen(true);
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // 다운로드
  const handleDownload = async () => {
    try {
      setLoadingText("이미지를 다운로드하는 중입니다.")
      setIsLoading(true);

      /////////////////// url -> b64 호출
      const b64Data = await urlToB64(resultImg[0]);
      //////////////////// b64 -> canvas
      const canvas = await b64ToCanvas(b64Data);
      const ref = { current: canvas }
      downloadImage(ref);
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if(images.length>0 && stepAutoGen === 'uploaded') {
      handleBackgroundRemoveClick();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if(stepAutoGen === 'analyzed') {
      handleMaskKeyword();
      addMessage(1);
      addMessage(2);
    }
    if(stepAutoGen === 'masked') {
      handleGenerate();
      addMessage(3);
    }
    if(stepAutoGen === 'error') {
      addMessage(5);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepAutoGen])

  useEffect(() => {
    const file = fileInputRef.current;
    if (file !== null) {
      file.addEventListener('dragover', handleDragOver);
      file.addEventListener('drop', handleDrop);

      return () => {
        file.removeEventListener('dragover', handleDragOver);
        file.removeEventListener('drop', handleDrop);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /********************************/
  const msgList = [
    "로보브러시 스튜디오를 시작합니다.  제품 이미지를 올려주세요.",
    "\"" + analyzedData + "\" 이미지를 올려주셨네요! 광고를 준비 해드릴게요.",
    "로보브러시 AI가 \"" + analyzedData + "\" 광고에 어울리는 소재를 고민하고 있어요. 잠시만 기다려주세요.",
    "광고 이미지 제작을 시작합니다. 로보브러시AI 가 광고를 그리는동안 커피 한 잔 하고 오는건 어떨까요?",
    "로보브러시 AI가 \"" + analyzedData + "\" 광고 이미지를 완성했어요! 결과물을 확인해볼까요?",
    "생성 중 문제가 발생했습니다. 하단의 업로드 버튼으로 생성을 다시 시도해주세요."
  ]
  const [messages, setMessages] = useState([msgList[0]]); // 상태 관리: 메시지 배열
  // 메시지 추가 함수
  const addMessage = (index) => {
    setMessages((prevMessages) => [...prevMessages, msgList[index]]);
  };
  const resetMessage = () => {
    setMessages([msgList[0]]);
  }

  // 편집 시작하기 시 url to b64 for edit
  const handleEditStart = async () => {
    try {
      setLoadingText("AI 캔버스를 로딩중입니다.")
      setIsLoading(true);
      const b64Data = await urlToB64(resultImg[0]);

      navigate('/mycanvas-edit', { state: { image: {b64image: b64Data, url: resultImg[0]} }});
    } catch (error) {
      setModalMsg(ModalMsg().imgLoadFailed);
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='UniteGenerater'>
      <AlertModal 
        isOpen={isOpen} 
        setIsOpen={setIsOpen} 
        message={modalMsg}></AlertModal>
      <div className='flex flex-col w-[100vw] h-full pt-4 lg:w-full '>
        <div className='flex w-full h-[40px] min-h-[40px]'>
          {((images.length>0 || resultImg.length>0) && isResultVisible===false) && <button
              onClick={() => setIsResultVisible(!isResultVisible)}
              className="lg:hidden my-1 ml-auto mr-6 p-2 text-white rounded-full bg-gradient-to-r from-blue-500 to-purple-500"
            >
              <Images size={16}/>
            </button>
          }
        </div>
        <div className='flex relative justify-center 
                        w-full h-[95%] overflow-hidden
                        lg:justify-between lg:min-h-[720px] lg:px-[4%]'>
          <MsgContainer isResultVisible={isResultVisible}>
            {messages.map((msg, index) => (
              index === 0 ? (
                <MsgBox key={index} msg={msg} type="default" />
              ) : (
                <MsgBox key={index} msg={msg} type="currentStep" />
              )
            ))}
            {/* 첫 메시지 출력 중일 때만 샘플이미지 출력 */}
            {messages.length === 1 && isLoading === false && <StudioSampleImage handleImageUpload={handleImageUpload} />}

            {isMobile ?
              isLoading === false &&
              <MobileUploadBtn
                openFilePicker={openFilePicker} 
                handleImageUpload={handleImageUpload} 
                fileInputRef={fileInputButtonRef}
              /> :
              <ChatArea
                isLoading={isLoading}
                openFilePicker={openFilePicker} 
                handleImageUpload={handleImageUpload} 
                fileInputRef={fileInputButtonRef}>
              </ChatArea>
            }
          </MsgContainer>
          {/* 이미지 업로드 / 이미지 결과 컴포넌트 들어감 */}
          <UploadContainer isResultVisible={isResultVisible}>
            <div className='absolute inset-0 w-[100%] h-[50px] min-h-[50px] rounded-t-[20px]
                            bg-white
                            lg:hidden '>
              <button
                onClick={() => setIsResultVisible(!isResultVisible)}
                className="absolute top-1 right-1 mb-1 mr-0 p-2 text-black rounded-full"
              >
                <CircleX size={24}/>
              </button>
            </div>
            {isLoading && <SmallLoadingIndicator text={loadingText} />}
            {resultImg.length > 0 ? (
              <div className='flex flex-col w-full gap-8 items-center mt-[40px] lg:mt-0'>
                <div className='flex items-center 
                                max-w-[512px] max-h-[512px]
                                lg:w-full w-[90%] lg:h-full h-[90%] '>
                  <img src={resultImg[0]} alt="Uploaded" className='object-contain w-full h-full aspect-square'/>
                </div>
                <div className='grid 
                                grid-cols-2 gap-6
                                lg:grid-cols-1 lg:gap-x-2
                                xl:grid-cols-2 xl:gap-x-6'>
                  <GradientOutlineButton 
                    text="로보브러시로 편집하기" 
                    size='w-[100%] h-[36px] sm:h-[50px]'
                    handleClick={handleEditStart}
                    btnId='studio-edit' />
                  <GradientOutlineButton 
                    text="다시 생성하기" 
                    leftIcon={<FaCoins className="text-[14px] sm:text-[24px] align-middle" />}
                    size='w-[100%] h-[36px] sm:h-[50px]'
                    handleClick={handleGenerate} 
                    btnId='studio-regen' />
                  <OutlineButton 
                    text="복사" 
                    size='w-[100%] h-[36px] sm:h-[50px]'
                    handleClick={handleCopy} 
                    btnId='studio-copy' />
                  <OutlineButton 
                    text="다운로드" 
                    size='w-[100%] h-[36px] sm:h-[50px]'
                    handleClick={handleDownload} 
                    btnId='studio-download' />
                </div>
              </div>
            ) : images.length === 0 ? (
              <div 
                onDrop={handleDrop} 
                onDragOver={handleDragOver}
                className="relative w-full justify-center">
                <UploadButton
                  handleImageUpload={handleImageUpload} 
                  fileInputRef={fileInputRef}>
                  <button
                    id='studio-upload-right' 
                    onClick={openFilePicker} 
                    className=' flex flex-col justify-center items-center
                        rounded-[8px] border-dashed border-4 border-gray-700 
                        bg-[#111820] font-bold text-[#6B80C7]
                        w-full aspect-square
                        lg:max-w-[512px]'
                  >
                    <Upload size={50}/>
                    편집을 원하는 이미지를 끌어다 넣어보세요
                  </button>
                </UploadButton>
              </div>
            ): (
              <div className='flex items-center max-w-[512px] max-h-[512px] aspect-square'>
                <img src={images[images.length - 1].image.src} alt="Uploaded" className='w-full h-full object-contain'/>
              </div>
            )}
          </UploadContainer>
        </div>
      </div>
    </div>
  )
}

const MsgContainer = ( { children, isResultVisible }) => {
  return (
    <div className={`
      flex-col absolute ${isResultVisible ? 'hidden' : 'flex'}
      rounded-[20px] bg-[#111820]
      min-w-[calc(100vw-74px)] min-h-[58%]
      mb-4 mx-[5%] px-[3%] gap-4
      lg:flex lg:relative 
      lg:min-w-[60.4%] lg:w-[60.4%] lg:min-h-[720px] lg:h-full
      lg:mx-0 pt-[3%] lg:pb-[1.5%] lg:px-[1.5%] `}
      >
      { children }
    </div>
  )
}

const MsgBox = ({ msg, type }) => {
  const baseStyles = `break-keep inline-block 
                      px-[3%] py-[16px] 
                      rounded-[15px] font-bold text-[13px]
                      lg:max-w-[85%] lg:text-[25px] lg:rounded-[25px] lg:w-fit`; 
                      // inline-block으로 너비를 텍스트에 맞춤
  const typeStyles =
    type === "default"
      ? "bg-white text-black"
      : "bg-gradient-to-r from-blue-500 to-purple-500 text-white";

  return (
    <div
      className={`${baseStyles} ${typeStyles}`}
    >
      {msg}
    </div>
  );
};

const ChatArea = ( { isLoading, openFilePicker, handleImageUpload, fileInputRef } ) => {
  return (
    <div className='lg:w-full lg:mx-0 lg:mb-0 lg:p-3 
      flex items-center justify-between 
      w-[110%] h-[56px] 
      mt-auto mb-[-70px] mx-[-5%] p-1 
      rounded-[12px] bg-white '>
      <UploadButton
        handleImageUpload={handleImageUpload} 
        fileInputRef={fileInputRef}
        >
        <button className={`flex ${gradientBg} text-white rounded-full w-[40px] h-[40px] items-center justify-center`}
            id='studio-upload-left'
            onClick={openFilePicker}
            disabled={isLoading === true}
          >
          <ImagePlus size={24} color="#FFFFFF"/>
        </button>
      </UploadButton>
      <div className='w-[70%] lg:ml-6 ml-2 mr-auto text-gray-400 lg:text-[25px] text-[14px] font-bold'>
        제품이미지를 업로드 해주세요. 
      </div>
      {/* <button className='flex bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full w-[40px] h-[40px] items-center justify-center'>
        <Send size={24} color="#FFFFFF"/>
      </button> */}
    </div>
  );
};

const MobileUploadBtn = ( { openFilePicker, handleImageUpload, fileInputRef } ) => {
  return (
    <div className={`flex flex-col items-center justify-between w-[100%] text-white text-lg font-bold
      mt-auto mb-[-70px] mx-auto p-1 rounded-full`}>

      <div className={`flex w-[100%] h-[56px] mb-[10px] p-[2px] rounded-full ${gradientBg} hover:scale-[.97]`}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <button className={`flex w-[100%] h-[100%] bg-[#111820] rounded-full items-center justify-center`}
            id='studio-upload-left'
            onClick={openFilePicker}>
          이미지 업로드
        </button>
      </div>

      <div className={`flex w-[100%] h-[56px] mb-[-65px] p-[2px] rounded-full ${gradientBg} hover:scale-[.97]`}>
        <input
          id='studio-camera-upload'
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
        <button className={`flex w-[100%] h-[100%] bg-[#111820] rounded-full items-center justify-center`}
          id='studio-camera'
          onClick={() => document.getElementById("studio-camera-upload").click()}>
          촬영으로 업로드
        </button>
      </div>

    </div>
  );
};

const UploadContainer = ( { children, isResultVisible } ) => {
  return (
    <div
      className={`flex flex-col box-border
        absolute fixed items-center justify-center
        rounded-[20px] border-2 border-black bg-[#111820]
        min-w-[calc(100vw-60px)] w-auto md:h-full h-[80%] 
        mb-auto mx-[3%] py-[2.5%] px-[2.5%]
        transform transition-transform duration-300 
        lg:relative
        lg:min-w-[35%] lg:w-[35%] lg:min-h-[720px] 
        lg:mt-0 lg:mx-0
        lg:translate-x-0
        ${isResultVisible ? "translate-x-0" : "translate-x-[120%]"}`}
    >
       { children }
    </div>
  )
}

const UploadButton = ( { children, handleImageUpload, fileInputRef } ) => {
  return (
    <div className='lg:flex lg:justify-center'>
      <input
        id='concept-gen-upload'
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      { children }
    </div>
  )
}

export default StudioGenerator