import { useAuth } from "../../context/AuthContext";
import { X, File, Siren } from "lucide-react";
import { gradientBg } from "../modules/colors";
import { FabIcon } from "./FabIcon";

const FabMenu = ({ onMenuSelect, onClose }) => {
  const { currentUser } = useAuth();

  const menus = [
    { label: "오류제보", icon: Siren },
    { label: "건의사항", icon: File },
  ];

  return (
    <div className="px-4 pb-2 md:p-0 w-[100vw] md:w-auto ">
      <div className={`inset-0 p-[3px] ${gradientBg} rounded-lg md:w-auto`}>
        <div className="relative bg-[#24303F] text-white rounded-lg shadow-lg p-6 flex flex-col gap-2 mx-auto">
          
          <button
            onClick={onClose}
            className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600 transition"
          >
            <X className="w-5 h-5 text-white" />
          </button>

          <FabIcon />

          <p className="text-left text-[#d9d9d9] md:text-[20px] text-[16px] md:pr-4 pr-0 py-2">
            {currentUser ? `${currentUser.name || currentUser.nickname} 님, ` : ""}
            <span className="block md:inline">안녕하세요! 어떤 도움이 필요하신가요?</span>
          </p>

          {menus.map(({ label, icon: Icon }, index) => (
            <button
              id={`fab-${label === "오류제보" ? "bug" : label === "건의사항" ? "suggest" : label}`}
              key={index}
              onClick={() => onMenuSelect(label)} // 클릭 시 Fab 컴포넌트 변경
              className="flex items-center gap-4 py-2 hover:bg-gray-800 text-left transition"
            >
              <Icon className="w-5 h-5 text-white" />
              {label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FabMenu;