import React, { useState } from "react";
import { ThumbsUp, ThumbsDown, X } from "lucide-react";
import { gradientBg } from "../modules/colors";
import firestore from "../../firebaseConfig";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import { useFab } from "../../context/FabContext";

const FabLike = ({ onClose }) => {
  const { currentUser } = useAuth();
  const { likeData } = useFab();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [completeText, setCompleteText] = useState("");

  const handleLikeSubmit = async (like) => {
    try {
      await addDoc(collection(firestore, "fabLike"), {
        sub: currentUser?.sub || "",
        name: currentUser?.nickname || currentUser?.name || "",
        url: likeData.url,
        tool: likeData.tool,
        like: like,
        createdAt: serverTimestamp(),
      });

      setCompleteText("소중한 의견 감사합니다.");
      setIsSubmitted(true);

      setTimeout(() => {
        onClose();
        setIsSubmitted(false);
      }, 3000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setCompleteText("전송 중 오류가 발생했습니다.");
      setIsSubmitted(true);

      setTimeout(() => {
        onClose();
        setIsSubmitted(false);
      }, 3000);
    }
  };

  return (
    <div className="px-4 pb-2 md:p-0">
      <div className={`inset-0 p-[3px] ${gradientBg} rounded-lg`}>
        <div className="relative bg-[#24303F] text-white rounded-lg shadow-lg p-6">

          {isSubmitted ? (
            <p className="text-center text-[20px] text-white mt-6 px-2">{completeText}</p>
          ) : (
            <>
              <div className="absolute top-2 right-2 flex gap-2">
                <button
                  onClick={onClose}
                  className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600 transition"
                >
                  <X className="w-5 h-5 text-white" />
                </button>
              </div>

              <p className="pt-8 px-4 md:text-[20px] text-[16px] text-white">
                생성한 이미지가 마음에 드셨나요?
              </p>

              <div className="flex justify-center gap-8 mt-6 mb-4">
                <button
                  id="fab-like"
                  className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-600 transition"
                  onClick={() => handleLikeSubmit("like")}
                >
                  <ThumbsUp className="w-6 h-6 text-white" />
                </button>
                <button
                  id="fab-dislike"
                  className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-600 transition"
                  onClick={() => handleLikeSubmit("dislike")}
                >
                  <ThumbsDown className="w-6 h-6 text-white" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FabLike;