import { createContext, useContext, useEffect, useState } from "react";

const FabContext = createContext();

export const FabProvider = ({ children }) => {
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isLikeVisible, setIsLikeVisible] = useState(false);
  const [likeData, setLikeData] = useState('')

  const openFabLike = (url) => {
    setTimeout(() => {
      if (Array.isArray(url)) {
        url = url[0];
      }
  
      const fileName = url.split("/").pop(); // /로 나눈 뒤 마지막 배열 추출
      const tool = fileName.substring(21, 24); // 21~23번째 문자 추출
  
      setLikeData({ url, tool });
      setIsMenuVisible(false);
      setIsLikeVisible(true);
    }, 1500);
  };

  // FabLike 출력 후 12초후 자동 닫힘
  useEffect(() => {
    if(isLikeVisible) {
      setTimeout(() => {
        setIsLikeVisible(false)
      }, 12000);
    }
  }, [isLikeVisible])

  return (
    <FabContext.Provider value={{
      isButtonVisible, setIsButtonVisible,
      isLikeVisible, setIsLikeVisible,
      isMenuVisible, setIsMenuVisible,
      likeData, setLikeData, openFabLike,
    }}>
      {children}
    </FabContext.Provider>
  );
};

export const useFab = () => useContext(FabContext);