import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const QnA = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const qnaData = [
    {
      title: 'AI 캔버스와 AI 스튜디오의 차이점이 무엇인가요?',
      subtitle: (
        <>
          AI 캔버스를 통해 이미지 생성, 이미지 조합 기능을 이용하실 수 있으며,<br />
          AI 스튜디오는 제품 사진을 업로드하면 AI가 제품을 분석하여 광고 가능한 이미지로 완성하는 서비스입니다.
        </>
      ),
      content: []
    },
    {
      title: '환불 정책은 어떻게 되나요?',
      subtitle: '구매 이후 7일 이내 환불을 신청해주시면 아래 조건에 따라 환불이 가능합니다. 자세한 사항은 아래 내용을 읽어주세요.',
      content: [
        {
          text: '청약 철회',
          subItems: [
            {
              text: [
                '구매 후 7일 이내에 환불 신청이 가능합니다.',
                'AI 스튜디오 구독제의 경우, 구독 결제 시점으로부터 7일 이내 신청 시 전액 환불이 가능합니다.'
              ],
            },
          ]
        },
        {
          text: '환불절차',
          subItems: [
            {
              text: [
                '환불 신청은 사이트 하단에 기재된 이메일 또는 business@imaginery.net으로 가입한 계정을 보내주시면 됩니다.',
                '환불 신청 후 3 영업일 이내에 환불이 진행됩니다.'
              ],
            },
          ]
        },
        {
          text: '환불금액 산정',
          subItems: [
            {
              text: [
                'AI 캔버스 사용을 위한 크레딧: 이미 차감된 유료 크레딧을 제외한 잔액에 대해 환불됩니다.',
                'AI 스튜디오 구독제: '
              ],
              subSubItems: [
                { text: '구독 결제 후 7일 이내 신청 시 AI 캔버스 및 AI 스튜디오 사용 이력이 없는 경우 전액 환불.' },
                { text: '구독 결제 후 7일 이후 신청 시 일할 계산에 따라 환불.' }
              ]
            },
          ]
        },
        {
          text: '환불 제한 사유',
          subItems: [
            {
              text: [
                '무료 크레딧은 환불 대상이 아닙니다.',
                'AI 스튜디오 구독제의 경우, 최근 구입 혹은 갱신된 결제 건에 대해서만 환불이 가능합니다.'
              ],
            },
          ]
        },
        {
          text: '기타사항',
          subItems: [
            {
              text: [
                '환불 정책은 구매 시점의 정책을 따릅니다.',
                '환불 관련 문의사항은 고객센터를 통해 안내 받으실 수 있습니다.'
              ],
            },
          ]
        },
      ]
    }
  ];

  const renderSubSubItems = (subSubItems) => {
    const romanize = (num) => {
      const lookup = {x:10,ix:9,v:5,iv:4,i:1};
      let roman = '';
      for (let i in lookup) {
        while (num >= lookup[i]) {
          roman += i;
          num -= lookup[i];
        }
      }
      return roman;
    };

    return subSubItems.map((subSubItem, subSubIdx) => (
      <div key={subSubIdx} className='ml-[10px] sm:ml-[20px]'>
        <p className='text-[11px] sm:text-[16px]'>{`${romanize(subSubIdx + 1)}. ${subSubItem.text}`}</p>
      </div>
    ));
  };

  const renderSubItems = (subItems) => {
    return subItems.map((subItem, subIdx) => (
      <div key={subIdx} className='ml-[10px] sm:ml-[20px]'>
        {subItem.text.map((line, lineIndex) => (
          <p className='text-[11px] sm:text-[16px]' key={lineIndex}><span className='text-xl'>•</span> {line}</p>
        ))}
        {subItem.subSubItems && renderSubSubItems(subItem.subSubItems)}
      </div>
    ));
  };

  const renderContentItems = (contentItems) => {
    return contentItems.map((contentItem, idx) => (
      <div key={idx} className='ml-[10px] sm:ml-[20px]'>
        <p className='text-[11px] sm:text-[16px]'>{`${idx + 1}. ${contentItem.text}`}</p>
        {contentItem.subItems && renderSubItems(contentItem.subItems)}
      </div>
    ));
  };

  return (
    <div className='text-white mx-auto p-0 px-[20px] max-w-[850px] my-[50px] sm:my-[100px] pb-[40px]'>
      <h1 className='text-[20px] sm:text-[40px] font-bold mb-[20px] sm:mb-[50px] text-center'>궁금해하실 내용을 모아봤어요.</h1>
      {qnaData.map((item, index) => (
        <div key={index} className='my-[20px] pt-[20px] sm:my-[30px] sm:pt-[30px] border-t-[3px] border-white'>
          <h3 className={`text-[14px] sm:text-[24px] font-bold text-left flex items-center cursor-pointer leading-tight hover:text-[#ffc700]
            ${openIndex === index ? 'text-[#ffc700]' : ''}`}
            onClick={() => handleToggle(index)} >
            {`${index + 1}. ${item.title}`}
            <FontAwesomeIcon
              icon={openIndex === index ? faChevronDown : faChevronRight}
              className='ml-auto mr-[20px]'
            />
          </h3>
          {openIndex === index && (
            <div className='w-[85%] mt-[20px] ml-[10px] sm:mt-[30px] sm:ml-[30px] flex flex-col gap-[10px]'>
              {item.subtitle && <h4 className='text-[12px] sm:text-[15px] font-bold'>{item.subtitle}</h4>}
              {item.content.length > 0 && renderContentItems(item.content)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QnA;