import ReactMarkdown from 'react-markdown';

const TermAds = () => {
  const md = `
## 광고성 정보 수신 동의 안내문

로보브러시는 서비스와 관련된 유용한 정보 및 다양한 혜택을 제공하기 위해 광고성 정보를 전송하고자 합니다. 이에 따라 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 의거하여 이용자의 사전 동의를 받고 있습니다.

### 광고성 정보의 전송 목적

- 로보브러시의 신규 서비스 및 기능 안내
- 맞춤형 이벤트 및 프로모션 정보 제공
- 이용자 혜택 안내 및 마케팅 정보 전달

### 전송 방법

- 이메일, 문자 메시지(SMS/MMS), 알림(Push Notification) 등

### 수신 동의 철회

이용자는 언제든지 광고성 정보 수신 동의를 철회할 수 있으며, 동의 철회 시 광고성 정보 전송을 즉시 중단합니다.

- 철회 방법: 로보브러시 로그인 > 내 정보 설정 변경

### 동의 거부 권리 안내

광고성 정보 수신 동의를 거부할 권리가 있으며, 동의하지 않아도 로보브러시 서비스 이용에 제한은 없습니다.
  `;
  
  return (<ReactMarkdown className="prose">{md}</ReactMarkdown>);
};

export default TermAds;