import ReactMarkdown from 'react-markdown';

const TermPrivacy = () => {
  const md = `
## 로보브러시 개인정보 처리방침

본 서비스( 이하 로보브러시)는 이용자의 개인정보 보호를 중요시하며, 「개인정보 보호법」 및 관련 법령을 준수하여 다음과 같이 개인정보를 처리합니다.

### 1. 개인정보의 처리 목적

로보브러시는 다음의 목적을 위하여 개인정보를 처리합니다. 처리 목적 이외의 용도로 이용되지 않으며, 목적이 변경될 경우 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.

- 회원가입 및 서비스 이용 관리: 회원 가입의사 확인, 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지
- 서비스 제공: 콘텐츠 제공, 요금 결제 및 정산
- 마케팅 및 광고 활용(별도 동의 시): 신규 서비스 개발, 맞춤 서비스 제공, 이벤트 및 광고 정보 제공, 접속빈도 파악 또는 회원의 서비스 이용 통계

### 2. 처리하는 개인정보의 항목

로보브러시는 다음과 같은 개인정보를 처리합니다.

- 회원가입 및 관리
    - 필수 항목: 이름, 이메일 주소, 비밀번호, 연락처
    - 선택 항목: 주소, 생년월일, 성별
- 서비스 제공
    - 필수 항목: 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제 기록
    - 선택 항목: 신용카드 정보, 은행계좌 정보
- 마케팅 및 광고 활용
    - 선택 항목: 이메일, 연락처, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보

### 3. 개인정보의 보유 및 이용 기간

로보브러시는 개인정보 보유 및 이용 기간을 다음과 같이 정합니다.

- 회원가입 및 관리: 회원 탈퇴 시까지
    - 법령 위반 관련 수사·조사 진행 중인 경우: 종료 시까지
    - 서비스 관련 채권·채무관계 잔존 시: 정산 시까지
- 서비스 제공: 서비스 제공 완료 및 결제 정산 완료 시까지
    - 전자상거래 등 관련 기록: 관련 법령에 따라 보관 (계약 관련 기록 5년, 소비자 불만 및 분쟁처리 기록 3년 등)
- 마케팅 및 광고 활용: 동의 철회 또는 회원 탈퇴 시까지

### 4. 개인정보 처리 위탁

로보브러시는 원활한 개인정보 업무 처리를 위해 다음과 같은 업무를 위탁합니다.

- 수탁자: 이메일 및 문자 발송업체
- 업무 내용: 마케팅 및 안내 정보 발송, 결제 관련 업무
- 위탁 기간: 위탁업무 종료 후 즉시 파기

### 5. 개인정보 파기 절차 및 방법

로보브러시는 개인정보가 불필요하게 되었을 때 지체 없이 파기합니다.

- 파기 절차: 파기 사유 발생 시 개인정보 보호책임자의 승인을 받아 파기
- 파기 방법: 전자 파일은 복구 불가능한 방법으로 삭제하며, 종이 문서는 분쇄 또는 소각

### 6. 정보주체 및 법정대리인의 권리·의무

이용자는 개인정보 열람, 정정, 삭제, 처리 정지를 요구할 권리가 있으며, 이를 위해 로보브러시에 서면, 이메일 등으로 요청할 수 있습니다. 로보브러시는 요청을 받은 즉시 조치합니다.

### 7. 개인정보의 안전성 확보 조치

로보브러시는 개인정보 보호를 위해 다음과 같은 조치를 취하고 있습니다.

- 관리적 조치: 정기적 자체 감사, 개인정보 취급자 최소화
- 기술적 조치: 개인정보 암호화, 접속 기록 보관 및 관리
- 물리적 조치: 접근 제한, 문서 보안 장치 이용

### 8. 개인정보 자동 수집 장치의 설치·운영 및 거부

로보브러시는 맞춤형 서비스 제공을 위해 쿠키를 사용하며, 이용자는 브라우저 설정을 통해 쿠키 저장을 거부할 수 있습니다.

### 9. 개인정보 보호 책임자

로보브러시는 개인정보 보호 및 관련 문의를 위해 아래와 같이 책임자를 지정합니다.

- 이름: 신준호
- 이메일: junho.shin@imaginery.net

### 10. 개인정보 처리방침 변경

본 개인정보 처리방침은 변경될 수 있으며, 변경 시 로보브러시 웹사이트(www.robobrush.ai)를 통해 공지합니다.

본 개인정보 처리방침은 2025년 3월 27일부터 적용됩니다.
  `;
    
  return (
    <div className='flex mt-[56px] py-12 bg-white'>
      <ReactMarkdown className="prose mx-auto">{md}</ReactMarkdown>
    </div>
  );
};

export default TermPrivacy;