const DEPLOY_PREFIX= 'https://robobrush-backend.azurewebsites.net/';
//담당자용 백엔드
// const DEV_PREFIX= 'https://b0d5-1-223-77-27.ngrok-free.app/';
//아래는 상시 구동 개발 백엔드
// const DEV_PREFIX= 'http://1652119112d2a00a.ngrok.app/';

const config = {

  API_VERSION: 'api/v0/',

  REMOVE_BG_URL: 'https://robobrush-ai-h8hehpc7h2hjbxfr.eastus-01.azurewebsites.net/api/v0/remove-background/',
  // REMOVE_BG_URL: 'https://1652119112d2a00a.ngrok.app/api/v1/remove-background/',

  GOOGLE_CLIENT_ID: '862030905871-nekhvmskogdhci7p8hscar8naof1ji84.apps.googleusercontent.com',

  LOGIN_ENDPOINT:           DEPLOY_PREFIX+'user/login/',
  USER_GET_IMAGES:          DEPLOY_PREFIX+'user/get-images/',
  CREDIT_REFRESH_URL:       DEPLOY_PREFIX+'user/get-credits/',
  USER_UPDATE:              DEPLOY_PREFIX+'user/modify/',
  USER_DELETE:              DEPLOY_PREFIX+'user/remove/',

  ADMIN_GET_USERS:          DEPLOY_PREFIX+'admin/get-users/',
  ADMIN_HISTORY:            DEPLOY_PREFIX+'admin/get-all-images/',
  ADMIN_IMAGES_BY_SUB:      DEPLOY_PREFIX+'admin/get-user-images/',
  ADMIN_ADD_SAMPLE:         DEPLOY_PREFIX+'admin/add-sample/',
  ADMIN_REMOVE_SAMPLE:      DEPLOY_PREFIX+'admin/remove-sample/',
  
  URL_TO_B64_URL:           DEPLOY_PREFIX+'image/url-to-base64/',
  GET_GEN_IMAGELIST:        DEPLOY_PREFIX+'image/get-gen-images/',
  GET_SAMPLE_IMAGES:        DEPLOY_PREFIX+'image/get-sample-images/',

  PROMPT_GEN_APIURL:        DEPLOY_PREFIX+'api/generate/',
  REF_GEN_APIURL:           DEPLOY_PREFIX+'api/combine/',
  RE_GEN_APIURL:            DEPLOY_PREFIX+'api/re-generate/',
  MASKED_GEN_APIURL:        DEPLOY_PREFIX+'api/canvas-generate/',

  BG_REMOVE_APIURL:         DEPLOY_PREFIX+'api/bg-remove-analyze/',
  MASK_KEYWORD_APIURL:      DEPLOY_PREFIX+'api/mask-keyword/',
  STUDIO_APIURL:            DEPLOY_PREFIX+'api/studio/',
  
  PORTONE_CHECK_VAL:        DEPLOY_PREFIX+'common/validate-payment/',

  TERMS_AGREEMENT:          DEPLOY_PREFIX+'user/marketing/',
  
  // LOGIN_ENDPOINT:           DEV_PREFIX+'user/login/',
  // USER_GET_IMAGES:          DEV_PREFIX+'user/get-images/',
  // CREDIT_REFRESH_URL:       DEV_PREFIX+'user/get-credits/',
  // USER_UPDATE:              DEV_PREFIX+'user/modify/',
  // USER_DELETE:              DEV_PREFIX+'user/remove/',

  // ADMIN_GET_USERS:          DEV_PREFIX+'admin/get-users/',
  // ADMIN_HISTORY:            DEV_PREFIX+'admin/get-all-images/',
  // ADMIN_IMAGES_BY_SUB:      DEV_PREFIX+'admin/get-user-images/',
  // ADMIN_ADD_SAMPLE:         DEV_PREFIX+'admin/add-sample/',
  // ADMIN_REMOVE_SAMPLE:      DEV_PREFIX+'admin/remove-sample/',
  
  // URL_TO_B64_URL:           DEV_PREFIX+'image/url-to-base64/',
  // GET_GEN_IMAGELIST:        DEV_PREFIX+'image/get-gen-images/',
  // GET_SAMPLE_IMAGES:        DEV_PREFIX+'image/get-sample-images/',

  // PROMPT_GEN_APIURL:        DEV_PREFIX+'api/generate/',
  // REF_GEN_APIURL:           DEV_PREFIX+'api/combine/',
  // RE_GEN_APIURL:            DEV_PREFIX+'api/re-generate/',
  // MASKED_GEN_APIURL:        DEV_PREFIX+'api/canvas-generate/',

  // BG_REMOVE_APIURL:         DEV_PREFIX+'api/bg-remove-analyze/',
  // MASK_KEYWORD_APIURL:      DEV_PREFIX+'api/mask-keyword/',
  // STUDIO_APIURL:            DEV_PREFIX+'api/studio/',
  
  // PORTONE_CHECK_VAL:        DEV_PREFIX+'common/validate-payment/',

  // TERMS_AGREEMENT:          DEV_PREFIX+'user/marketing/',


};

export default config;
