import React, { useRef, useState, useEffect} from 'react';
import { Bold, Italic, TypeOutline, AlignLeft, AlignCenter, AlignRight, AArrowUp } from 'lucide-react';

const TextToolbox = ({ 
  isKeyboardVisible, 
  images, setImages,
  undoStack, setUndoStack, setRedoStack, 
  selectedId,
  fontSize, setFontSize, 
  fontFamily, setFontFamily, 
  color, setColor, 
  align, setAlign, 
  setVerticalAlign,
  textBold, setTextBold,
  textItalic, setTextItalic,
  textStroke, setTextStroke, 
  textStrokeColor, setTextStrokeColor,
  }) => {

  const inputRef = useRef();
  // 클릭 감지를 위한 ref
  const fontSizeRef = useRef(null);
  const strokeRef = useRef(null);
  const alignRef = useRef(null);

  const [showFontSizeInput, setShowFontSizeInput] = useState(false);
  const [showStrokeSizeInput, setShowStrokeSizeInput] = useState(false);
  const [showtextAlign, setShowTextAlign] = useState(false);

  // 폰트 리스트
  // 새 폰트 추가 시 tailwind.config.js 내 fontFamily에도 추가해야함
  const fonts = [
    { name: 'Sans Serif', viewName: 'Sans Serif', className: 'font-sans' },
    { name: 'Serif', viewName: 'Serif', className: 'font-serif' },
    { name: 'Mono', viewName: 'Mono', className: 'font-mono' },
    { name: 'Nanum Gothic', viewName: '나눔 고딕', className: 'font-nanum-gothic' },
    { name: 'Nanum Gothic Coding', viewName: '나눔 고딕 코딩', className: 'font-nanum-gothic-coding' },
    { name: 'Nanum Myeongjo', viewName: '나눔 명조', className: 'font-nanum-myeongjo' },
    { name: 'Nanum Pen Script', viewName: '나눔 펜 스크립트', className: 'font-nanum-pen-script' },
    { name: 'Do Hyeon', viewName: '도현', className: 'font-do-hyeon' },
    { name: 'Jua', viewName: '주아', className: 'font-jua' },
    { name: 'Noto Sans KR', viewName: '노토 산스 KR', className: 'font-noto-sans-kr' },
    { name: 'Gothic A1', viewName: '고딕 A1', className: 'font-gothic-a1' },
    { name: 'Noto Serif KR', viewName: '노토 세리프 KR', className: 'font-noto-serif-kr' },
  ];

  // 입력폼의 값 변경 시, 폼 상태와 선택된 오브젝트(있는 경우)를 업데이트합니다.
  const handleInputChange = (key, value) => {
    // 폼 상태 업데이트
    switch (key) {
      case 'fontSize':
        setFontSize(value);
        // 폰트 사이즈에 따른 스트로크 제한
        const strokeLimit = parseInt(value*0.17, 10)
        if (textStroke > strokeLimit) {
          setTextStroke(strokeLimit)
          setImages(prev =>
            prev.map(item =>
              item.id === selectedId ? { ...item, textStroke: strokeLimit } : item
            )
          );
        }
        break;
      case 'fontFamily':
        setFontFamily(value);
        break;
      case 'color':
        setColor(value);
        break;
      case 'align':
        setAlign(value);
        break;
      case 'verticalAlign':
        setVerticalAlign(value);
        break;
      case 'textBold':        
        setTextBold(value);
        break;
      case 'textItalic':
        setTextItalic(value);
        break;
      case 'textStroke':
        setTextStroke(value);
        break;
      case 'textStrokeColor':
        setTextStrokeColor(value);
        break;
      default:
        break;
    }
    // 선택된 오브젝트가 있다면 해당 오브젝트의 속성도 업데이트
    if (selectedId && selectedId.split('-')[0] === 'text'){
      // if 문에서 걸러지는 케이스는 따로 관리
      if (key!=='color' && key!=='textStrokeColor' && key!=='fontSize' && key!=='textStroke') {
        // undoStack에 현재 상태(images)를 저장한 후
        setUndoStack([...undoStack, images]);
        // redo 스택은 초기화
        setRedoStack([]);
      }
      setImages(prev =>
        prev.map(item =>
          item.id === selectedId ? { ...item, [key]: value } : item
        )
      );
    }
  };

  // 폰트 사이즈, 스트로크 조정 시작 시
  const handleRangeChangeStart = () => {
    if (selectedId && selectedId.split('-')[0] === 'text') {
      setUndoStack([...undoStack, images]);
      setRedoStack([]);
    }
  }
  // 폰트 컬러, 스트로크 컬러 조정 시작 시
  const handleColorChangeStart = () => {
    if (selectedId && selectedId.split('-')[0] === 'text') {
      setUndoStack([...undoStack, images]);
      setRedoStack([]);
    }
  };
  // 말풍선(색/사이즈 조정) 바깥 클릭 감지
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (fontSizeRef.current && !fontSizeRef.current.contains(event.target)) {
        setShowFontSizeInput(false);
      }
      if (strokeRef.current && !strokeRef.current.contains(event.target)) {
        setShowStrokeSizeInput(false);
      }
      if (alignRef.current && !alignRef.current.contains(event.target)) {
        setShowTextAlign(false);
      }
    };
    // mousedown과 touchstart 이벤트로 외부 클릭 감지
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    (
      <div className='w-auto sm:w-[auto] bg-[#d9d9d9] p-1 shadow-md flex items-center gap-1 rounded-md
                      sm:p-2 sm:gap-2 sm:rounded-lg'>
        {/* 폰트 */}
        <div className="h-5 sm:h-9">
          <select
            id="tool-fontSelect"
            className={`${fonts.find(i => i.name===fontFamily)?.className} 
                        flex px-1 w-20 h-5 text-xs
                        border border-1 border-gray-500 rounded-md 
                        text-black justify-center 
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                        sm:px-2 sm:w-32 sm:h-9 sm:text-sm `}
            value={fontFamily}
            onChange={(e) => handleInputChange('fontFamily', e.target.value)}
          >
            {fonts.map((font) => (
              <option key={font.className} value={font.name} className={`${font.className} `}>
                {font.viewName}
              </option>
            ))}
          </select>
        </div>
        {/* 폰트 사이즈 */}
        <div className="relative h-5 sm:h-9"
          ref={fontSizeRef}
        >
          <button
            id='tool-fontSize'
            className={`rounded ${showFontSizeInput ? 'bg-blue-500 text-white' : 'text-black'}`}
            onClick={() => {setShowFontSizeInput(!showFontSizeInput)}}
          >
            <AArrowUp 
              className='w-5 h-5 m-auto stroke-[1.5]
                sm:w-9 sm:h-9'/>
          </button>
          {showFontSizeInput && (
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 z-10">
              {/* 말풍선 꼬리 */}
              <div className="relative">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full border-x-8 border-x-transparent border-b-8 border-b-white"></div>
              </div>
              {/* 팝업 내용 */}
              <div className="bg-white border rounded shadow-lg p-2 flex items-center">
                <input 
                  className= 'rounded'
                  type= 'range'
                  value={fontSize}
                  min={10} max={150}
                  onChange={(e) => handleInputChange('fontSize', parseInt(e.target.value, 10))}
                  onMouseDown={() => handleRangeChangeStart()}
                  onTouchStart={() => handleRangeChangeStart()}
                />
              </div>
            </div>
          )}
        </div>
        {/* 텍스트 색상 */}
        <div className="h-5 sm:h-9">
          <input
          // 여백없애기 위해 index.css에서 color-input을 선언함.
            id='tool-fontColor'
            className='w-5 sm:w-9 h-5 sm:h-9 border-1 border-gray-500 rounded-md color-input'
            type="color"
            value={color}
            onChange={e => handleInputChange('color', e.target.value)}
            onFocus={() => handleColorChangeStart()}
          />
        </div>
        {/* 볼드 */}
        <div className="h-5 sm:h-9">
          <button
            id='tool-fontBold'
            className={`rounded ${textBold ? 'bg-blue-500 text-white' : 'text-black'}`}
            onClick={() => handleInputChange('textBold', !textBold)}
          >
            <Bold className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
          </button>
        </div>
        {/* 기울이기 */}
        <div className="h-5 sm:h-9">
          <button
            id='tool-fontItalic'
            className={`rounded ${textItalic ? 'bg-blue-500 text-white' : 'text-black'}`}
            onClick={() => handleInputChange('textItalic', !textItalic)}
          >
            <Italic className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
          </button>
        </div>
        {/* 테두리 사이즈 */}
        <div className="relative h-5 sm:h-9"
          ref={strokeRef}
        >
          <button
            id='tool-strokeSize'
            className={`rounded ${showStrokeSizeInput ? 'bg-blue-500 text-white' : 'text-black'}`}
            onClick={() => setShowStrokeSizeInput(!showStrokeSizeInput)}
          >
            <TypeOutline className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
          </button>
          {showStrokeSizeInput && (
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 z-10">
              {/* 말풍선 꼬리 */}
              <div className="relative">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full border-x-8 border-x-transparent border-b-8 border-b-white"></div>
              </div>
              {/* 팝업 내용 */}
              <div className="bg-white border rounded shadow-lg p-2 flex items-center">
                <input 
                  className= 'rounded'
                  type= 'range'
                  value={textStroke}
                  min={0} max={parseInt(fontSize * 0.17, 10)}
                  onChange={(e) => handleInputChange('textStroke', parseInt(e.target.value, 10))}
                  onMouseDown={() => handleRangeChangeStart()}
                  onTouchStart={() => handleRangeChangeStart()}
                />
              </div>
            </div>
          )}
        </div>
        {/* 테두리 색상 */}
        <div className="flex flex-col gap-0 h-5 sm:h-9"
            id='tool-strokeColor'
            onClick={() => {if(inputRef.current) inputRef.current.click();}}>
          <TypeOutline className='w-5 h-3 sm:w-9 sm:h-9 m-auto stroke-[1.5]'/>
          <input
            ref={inputRef}
            className='w-5 sm:w-9 h-2 border-1 border-gray-500 rounded-md color-input color-input'
            type="color"
            value={textStrokeColor}
            onChange={e => handleInputChange('textStrokeColor', e.target.value)}
            onFocus={() => handleColorChangeStart()}
          />
        </div>
        {/* 수평 정렬 버튼 */}
        <div className="relative h-5 sm:h-9"
          ref={alignRef}
        >
          <button
            className={`rounded ${showtextAlign ? 'bg-blue-500 text-white' : 'text-black'}`}
            onClick={() => {setShowTextAlign(!showtextAlign)}}
          >
            {align === 'left' ? <AlignLeft className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
              : align === 'right' ? <AlignRight className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
              : <AlignCenter className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>}
          </button>
          {showtextAlign && (
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 z-10">
              {/* 말풍선 꼬리 */}
              <div className="relative">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full border-x-8 border-x-transparent border-b-8 border-b-white"></div>
              </div>
              {/* 팝업 내용 */}
              <div className="bg-white border rounded shadow-lg p-2 flex items-center">
                <button
                  id='tool-textLeft'
                  className={`rounded ${align === 'left' ? 'bg-blue-500 text-white' : 'text-black'}`}
                  onClick={() => handleInputChange('align', 'left')}
                >
                  <AlignLeft className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
                </button>
                <button
                  id='tool-textCenter'
                  className={`rounded ${align === 'center' ? 'bg-blue-500 text-white' : 'text-black'}`}
                  onClick={() => handleInputChange('align', 'center')}
                >
                  <AlignCenter className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
                </button>
                <button
                  id='tool-textRight'
                  className={`rounded ${align === 'right' ? 'bg-blue-500 text-white' : 'text-black'}`}
                  onClick={() => handleInputChange('align', 'right')}
                >          
                  <AlignRight className='w-5 sm:w-9 h-5 sm:h-9 m-auto stroke-[1.5]'/>
                </button>
              </div>
            </div>
          )}
        </div>
        {/* 수직 정렬 버튼 */}
        {/* {!isKeyboardVisible &&
          <div className="flex space-x-2 mb-4">
            <span className="self-center">Vertical Align:</span>
            <button
              className={`px-2 py-1 border rounded ${verticalAlign === 'top' ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}
              onClick={() => handleInputChange('verticalAlign', 'top')}
            >
              Top
            </button>
            <button
              className={`px-2 py-1 border rounded ${verticalAlign === 'middle' ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}
              onClick={() => handleInputChange('verticalAlign', 'middle')}
            >
              Middle
            </button>
            <button
              className={`px-2 py-1 border rounded ${verticalAlign === 'bottom' ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}
              onClick={() => handleInputChange('verticalAlign', 'bottom')}
            >
              Bottom
            </button>
          </div>
        } */}
      </div>
    )
  );
};

export default TextToolbox;