import { createContext, useState } from "react";

export const PromptContext = createContext();

export const PromptProvider = ({ children }) => {
  const [prompt, setPrompt] = useState('');
  const [currentImage, setCurrentImage] = useState(null);
  const [onConfirm, setOnConfirm] = useState(() => () => {});

  const confirmPrompt = (image, handleSubmit) => {
    setCurrentImage(image);
    setOnConfirm(() => () => {
      handleSubmit(image.prompt);
      setPrompt('');
      setCurrentImage(null);
    });
  };

  const cancelPrompt = () => {
    setCurrentImage(null);
  };

  return (
    <PromptContext.Provider value={{ prompt, setPrompt, confirmPrompt, cancelPrompt, currentImage, onConfirm }}>
      {children}
    </PromptContext.Provider>
  );
};